import { Stack, Flex, Box, Text } from '@chakra-ui/react'
import MP4Player from '../stateless/MP4Player'
import { ICard4 } from '../../graphql/year-review.query'

interface Props extends ICard4 {}

export default function Card4({
  highlightVideo: { url: video, thumbnail }
}: Props) {
  return (
    <Stack flexDirection="column" alignItems="center" color="white" w="100%">
      <Text fontSize={['16px', '24px']}>
        Dentre tantos vídeos legais, esse foi um que fez sucesso:
      </Text>

      <Flex
        flex="1"
        style={{
          aspectRatio: '285/509'
        }}
        alignItems="center"
        justifyContent="center"
        className="BOX VIDEO"
        maxWidth="80%"
      >
        <MP4Player src={video} poster={thumbnail} />
      </Flex>
    </Stack>
  )
}
