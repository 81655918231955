import { Box, Flex, SlideFade, Text } from '@chakra-ui/react'
import { ItemColored } from '../stateless/ItemColored'
import { HighlightAnimation } from '../stateless/HighlightAnimation'
import { numberWithCommas } from '../../utils/helpers/numberFormats'
import { ICard6 } from '../../graphql/year-review.query'
import UnderlineAnimation from '../stateless/UnderlineAnimation'
import hapiness from '../../lotties/hapiness.json'
import LottieAnimation from '../Animations/LottieAnimation'
interface Props extends ICard6 {}

export default function Card6({
  approvalRate,
  conversion,

  widdeConversion: conversionWidde,
  conversionIncrease: increaseConversion
}: Props) {
  return (
    <Flex
      height="100%"
      w="100%"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box zIndex={1} w="100%" height="100%" position="absolute">
        <LottieAnimation height={'100%'} animation={hapiness} delay={250} />
      </Box>
      <Box
        zIndex={2}
        flex="1"
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="end"
      >
        <SlideFade offsetY="20px" delay={0.9} in={true}>
          <Text px="16px" fontSize={['24px', '40px']} textAlign="center">
            Seus vídeos tiveram
            <br />
          </Text>
        </SlideFade>
        <SlideFade offsetY="20px" delay={1.05} in={true}>
          <Text
            px="16px"
            w="100%"
            fontSize={['40px', '60px']}
            textAlign="center"
          >
            <UnderlineAnimation>{+approvalRate}%</UnderlineAnimation> <br />
          </Text>
        </SlideFade>
      </Box>
      <Box flex="1" />
      <Box
        zIndex={2}
        flex="1"
        display="flex"
        alignItems="start"
        justifyContent="center"
      >
        <SlideFade offsetY="20px" delay={1.9} in={true}>
          <Text px="16px" fontSize={['24px', '40px']} textAlign="center">
            de aprovação dos seus clientes
          </Text>
        </SlideFade>
      </Box>
    </Flex>
  )
}
