import { Box, Flex, SlideFade, Text } from '@chakra-ui/react'
import { HighlightAnimation } from '../stateless/HighlightAnimation'
import { numberWithCommas } from '../../utils/helpers/numberFormats'
import { ICard6 } from '../../graphql/year-review.query'
import conversionBg from '../../lotties/conversion.json'
import LottieAnimation from '../Animations/LottieAnimation'
import UnderlineAnimation from '../stateless/UnderlineAnimation'
import Delay from '../stateless/Delay'
interface Props extends ICard6 {}

export default function Card6({
  approvalRate,
  conversion,

  widdeConversion: conversionWidde,
  conversionIncrease: increaseConversion
}: Props) {
  return (
    <Flex
      height="100%"
      w="100%"
      className="teste123"
      position="relative"
      display="flex"
      alignItems="center"
      leftContent="center"
      flexDirection="column"
    >
      <Box zIndex={1} w="100%" height="100%" position="absolute">
        <LottieAnimation height={'100%'} animation={conversionBg} delay={250} />
      </Box>
      <Box
        flex="1"
        zIndex={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        px="40px"
      >
        <SlideFade offsetY="20px" delay={1} in={true}>
          <Text fontSize="60px">
            <HighlightAnimation delay={1} bg="#000000" color="white">
              {numberWithCommas(increaseConversion)}%
            </HighlightAnimation>
          </Text>
        </SlideFade>
        <Box minHeight={['210px', '260px']}>
          <SlideFade offsetY="20px" delay={1.5} in={true}>
            <Text
              textAlign="left"
              color="black"
              fontSize={['18px', '24px']}
              mt="24px"
            >
              Foi o aumento da sua taxa de conversão.
            </Text>
          </SlideFade>

          <Delay delay={2.2}>
            <SlideFade offsetY="20px" in={true}>
              <Text
                textAlign="left"
                color="black"
                fontSize={['18px', '24px']}
                mt="24px"
              >
                Enquanto que a{' '}
                <b>
                  <UnderlineAnimation color="#000">
                    taxa de conversão geral
                  </UnderlineAnimation>
                </b>{' '}
                do seu ecommerce foi de{' '}
                <UnderlineAnimation color="#000">
                  {numberWithCommas(conversion)}%.
                </UnderlineAnimation>{' '}
              </Text>
            </SlideFade>
          </Delay>
          <Delay delay={3.5}>
            <SlideFade offsetY="20px" in={true}>
              <Text
                textAlign="left"
                color="black"
                fontSize={['18px', '24px']}
                mt="16px"
              >
                Para as pessoas que usaram a Widde a taxa de conversão{' '}
                <Delay delay={0.1}>
                  <HighlightAnimation color="#FFF" bg="#050508">
                    {numberWithCommas(conversionWidde)}%
                  </HighlightAnimation>
                </Delay>
              </Text>
            </SlideFade>
          </Delay>
        </Box>

        {/* {conversionWidde}%
            </Text>
            , enquanto que a média geral do seu ecommerce foi de{' '}
            {numberWithCommas(conversion)}% (a partir de quando começou com a
            Widde).
          </Text> */}
      </Box>
    </Flex>
  )
}
