import { animate } from "framer-motion";
import { useEffect, useRef } from "react";
import { numberWithCommas } from "../../utils/helpers/numberFormats";

type Props = {
  to: number;
  delay: number;
  duration?: number;
};
export function NumberCounter({ to, delay, duration = 1.2 }: Props) {
  const nodeRef: any = useRef();

  useEffect(() => {
    const node: any = nodeRef.current;

    node.textContent = 0;
    const controls = animate(0, to, {
      duration,
      delay,
      onUpdate(value) {
        node.textContent = numberWithCommas(value.toFixed(0));
      },
    });

    return () => controls.stop();
  }, [delay, to]);

  return <span ref={nodeRef} />;
}
