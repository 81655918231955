import { Box, Flex, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { NumberCounter } from '../Animations/NumbersCount'
import { ICard3 } from '../../graphql/year-review.query'

interface Props extends ICard3 {}

export default function Card3({
  viewsTotal: views,
  userViewsTotals: users,
  videosTotal: numberOfVideos
}: Props) {
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      color="white"
      w="100%"
    >
      <Text fontSize={['30px']}>Esse ano, você adicionou</Text>
      <Text fontSize={['48px']} color="pink.100" fontWeight="700">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.9,
            delay: 0
            // default: { ease: "easeOut" },
          }}
        >
          <NumberCounter to={+numberOfVideos} delay={0.3} /> vídeos
        </motion.div>
      </Text>
      <Text fontSize={['30px']}>teve um total de</Text>
      <Text fontSize={['48px']} color="secondary.100" fontWeight="700">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1,
            delay: 1.8
          }}
        >
          <NumberCounter to={+views} delay={1.8} /> views
        </motion.div>
      </Text>
      <Text fontSize={['30px']}>com um total de</Text>
      <Text fontSize={['48px']} color="orange.100" fontWeight="700">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1,
            delay: 3.6
          }}
        >
          <NumberCounter to={+users} delay={3.6} /> usuários
        </motion.div>
      </Text>
    </Flex>
  )
}
