export function toLottieColor(hex: string): [number, number, number] {
  // Ensure that the input is a valid hex color code
  const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
  const result = hexRegex.exec(hex)

  if (!result) {
    throw new Error('Invalid hex color code')
  }

  // Extract the RGB components
  const [, r, g, b] = result.map(value => parseInt(value, 16))

  return [
    Math.floor((r / 255) * 100) / 100,
    Math.floor((g / 255) * 100) / 100,
    Math.floor((b / 255) * 100) / 100
  ]
}
