import { IStoryCard } from '../../hook/useStoryFlow'
import { CardData, ClientTypeEnum } from '../../graphql/year-review.query'
import StoryLayout from '../../layouts/StoryLayout'
import Card10 from '../Cards/Card10'
import Card11 from '../Cards/Card11'
import Card12 from '../Cards/Card12'
import Card2 from '../Cards/Card2'
import Card3 from '../Cards/Card3'
import Card4 from '../Cards/Card4'
import Card5 from '../Cards/Card5'
import Card6 from '../Cards/Card6'
import Card6b from '../Cards/Card6b'
import Card7 from '../Cards/Card7'
import Card8 from '../Cards/Card8'
import Card9 from '../Cards/Card9'
import { toLottieColor } from '../../utils/toLottieColor'

interface IGaFlowProps {
  clientType: ClientTypeEnum
  data: CardData
}
export default function useWithoutGaFlow(data: IGaFlowProps) {
  const cardData = data?.data || ({} as CardData)

  const values: IStoryCard[] = [
    {
      id: 2,
      content: () => (
        <StoryLayout>
          <Card2 {...cardData} type={data?.clientType ?? ClientTypeEnum.free} />
        </StoryLayout>
      )
    },
    {
      id: 3,
      watch: [],
      duration: 15000,
      content: () => (
        <StoryLayout>
          <>
            <Card3 {...cardData} />
          </>
        </StoryLayout>
      )
    },
    {
      id: 4,
      watch: [],
      duration: 1000 * cardData.highlightVideo?.duration * 2.5,
      content: () => (
        <StoryLayout>
          <Card4 {...cardData} />
        </StoryLayout>
      )
    },
    {
      id: 5,
      watch: [],
      content: () => (
        <StoryLayout background="yellow">
          <Card5 {...cardData} />
        </StoryLayout>
      )
    },
    {
      id: 6,
      templates: [],
      watch: [cardData.approvalRate > 0],
      duration: 1000 * 30,
      content: () => (
        <StoryLayout p="0px">
          <Card6b {...cardData} />
        </StoryLayout>
      )
    },
    {
      id: 7,
      templates: [],
      duration: cardData.comments?.length * 1000 * 5 + 10000,
      watch: [!!cardData.comments?.length],
      content: () => (
        <StoryLayout p="0px">
          <Card7
            {...cardData}
            color={toLottieColor('#B625AA')}
            clientType={data.clientType}
          />
        </StoryLayout>
      )
    },
    {
      id: 8,
      templates: [],
      content: () => (
        <StoryLayout p="0px">
          <Card8 {...cardData} />
        </StoryLayout>
      )
    },
    {
      // watch: [],
      id: 9,
      duration: 9000000,
      content: () => (
        <StoryLayout p="0px">
          <Card9 {...cardData} />
        </StoryLayout>
      )
    },
    {
      id: 12,
      content: () => (
        <StoryLayout>
          <Card12 />
        </StoryLayout>
      )
    }
  ]
  return values
}
