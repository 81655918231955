import { useEffect, useState } from 'react'
import { Fireworks, useFireworks } from 'fireworks-js/dist/react'

export default () => {
  const { setEnabled, setOptions, enabled, options } = useFireworks({
    initialStart: true,
    initialOptions: {
      hue: {
        min: 0,
        max: 345
      },
      delay: {
        min: 2200,
        max: 2200
      },
      rocketsPoint: 50,
      speed: 1,
      acceleration: 1.05,
      friction: 0.96,
      gravity: 1,
      particles: 180,
      trace: 3,
      explosion: 6,
      autoresize: true,
      brightness: {
        min: 50,
        max: 80,
        decay: {
          min: 0.015,
          max: 0.03
        }
      },
      boundaries: {
        visible: false
      },
      sound: {
        enabled: true,
        // files: [
        //   '/assets/sounds/explosion0.mp3',
        //   '/assets/sounds/explosion1.mp3',
        //   '/assets/sounds/explosion2.mp3'
        // ],
        volume: {
          min: 1,
          max: 2
        }
      },
      mouse: {
        click: true,
        move: false,
        max: 10
      }
    }
  })

  useEffect(() => {
    let interval1 = setInterval(() => {
      // setTime((time) => time + 1);

      setOptions({ delay: { min: 5, max: 20 } })
    }, 2200)
    let interval2 = setInterval(() => {
      // setTime((time) => time + 1);

      setOptions({ delay: { min: 100, max: 250 } })
    }, 10000)

    return () => {
      clearInterval(interval1)
      clearInterval(interval2)
    }
  }, [])

  return (
    <Fireworks
      style={{
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: 1
      }}
      enabled={enabled}
      options={options}
    >
      <></>
    </Fireworks>
  )
}
