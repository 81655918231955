import { Text } from '@chakra-ui/react'

export function ItemColored({
  bg,
  color,
  children,
  value
}: {
  bg: string
  color: string
  children?: any
  value?: any
}) {
  return (
    <Text
      p="0"
      bg={bg}
      w="100%"
      color={color}
      fontSize={['40px', '68px']}
      fontWeight="800"
      lineHeight="56px"
      textAlign="center"
    >
      {children || value}
    </Text>
  )
}
