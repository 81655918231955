import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
export interface VideoPlayerProps {
  src: string | string[];
  poster?: string;
}

function getExtension(url: string) {
  return url.split(".").pop();
}

//@ts-ignore
export default memo(({ src, poster }: VideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement>({} as HTMLVideoElement);
  const _src = Array.isArray(src) ? src : [src];

  return (
    <video
      disablePictureInPicture
      preload="auto"
      poster={poster}
      ref={videoRef}
      // src={url}
      key={poster || "_video"}
      autoPlay={true}
      muted={false}
      controls={false}
      width="100%"
      playsInline
      height="100%"
      style={{
        objectFit: "cover",
        maxWidth: "unset",
      }}
    >
      {_src.filter(Boolean).map((url, index) => (
        <source key={index} src={url} type={`video/${getExtension(url)}`} />
      ))}
    </video>
  );
});
