import { useMemo } from 'react'

import { CardData, ClientTypeEnum } from '../graphql/year-review.query'
import useFreeFlow from '../components/StoryFlows/useFreeFlow'
import useGAFlow from '../components/StoryFlows/useGAFlow'
import useWithoutGaFlow from '../components/StoryFlows/useWithoutGaFlow'
export interface IStoryCard {
  id: number
  templates?: string[]
  watch?: Array<Boolean>
  duration?: number
  content: () => JSX.Element
  type?: string
}

export default function useStoryFlows(data: {
  clientType: ClientTypeEnum
  data: CardData
}) {
  console.log(`data`, data)
  const freeFlow = useFreeFlow(data)
  const GAFlow = useGAFlow(data)
  const withoutGaFlow = useWithoutGaFlow(data)
  const flows = useMemo(
    () => ({
      [ClientTypeEnum.free]: freeFlow,
      [ClientTypeEnum.GA]: GAFlow,
      [ClientTypeEnum.withoutGA]: withoutGaFlow
    }),
    [data]
  )
  const selectedFlow = flows[data.clientType]
  const filteredFlow = selectedFlow?.filter(item =>
    item.watch ? !item.watch.some(item => !item) : true
  )

  return filteredFlow
}
