import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    heading: "Barlow",
    body: "Lato",
  },
  colors: {
    white: "#fefefe",
    primary: {
      "100": "#050508",
    },
    secondary: {
      "100": "#E5F754",
    },
    pink: {
      "100": "#CF3AA2",
    },
    purple: {
      "100": "#5F36E0",
    },
    orange: {
      "100": "#EC6434",
    },
    green: {
      "100": "#7CF754",
    },
  },
  styles: {
    global: {
      body: {
        background: "#050508",
        fontWeight: "400",
        color: "#FFFFFF",
      },
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
        borderRadius: "8px",
        margin: 0,
        padding: 0,
        backgroundColor: ``,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "8px",
        margin: 0,
        backgroundColor: `#fefefe`,
      },
    },
  },
  components: {
    Text: {
      variants: {
        "highlight-yellow": () => ({
          color: "#000000",
          background: "#E5F754",
          padding: "0px 1px",
        }),
      },
    },
  },
});
