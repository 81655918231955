import { Flex, Image, Text, Stack, Box } from '@chakra-ui/react'

type BackgroundType = 'normal-black' | 'yellow'

interface Props {
  children: JSX.Element
  hiddeWiddeLogo?: boolean
  background?: BackgroundType
  p?: string
}

export default function StoryLayout({
  children,
  hiddeWiddeLogo,
  background = 'normal-black',
  p = '16px'
}: Props) {
  const bgs: { [key in BackgroundType]: string } = {
    'normal-black': 'primary.100',
    yellow: 'secondary.100'
  }
  const currentBg = bgs[background]
  return (
    <Flex
      p={p}
      pb="0"
      w="100%"
      h="100%"
      bg={currentBg}
      flexDir="column"
      bgSize="contain"
      position="relative"
      alignItems="center"
      bgRepeat="no-repeat"
      justifyContent="center"
      bgPosition="center top"
    >
      <Box
        top="0"
        w="100%"
        h="60px"
        zIndex={10}
        position="absolute"
        background="linear-gradient(to top, transparent 0%, rgba(5, 5, 8, 0.9) 100%);"
      ></Box>
      {/* {!hiddeWiddeLogo && (
        <Stack
          direction="row"
          alignItems="center"
          position="absolute"
          top="24px"
          left="16px"
        >
          <Image w="22px" h="22px" src="/assets/widde/widde-rounded-icon.png" />
          <Text fontSize="16px" fontWeight="700">
            Widde
          </Text>
        </Stack>
      )} */}
      {children}
    </Flex>
  )
}
