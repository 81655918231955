import { Box, Flex, SlideFade, Text } from '@chakra-ui/react'
import { ItemColored } from '../stateless/ItemColored'
import { HighlightAnimation } from '../stateless/HighlightAnimation'
import { numberWithCommas } from '../../utils/helpers/numberFormats'
import { ClientTypeEnum, ICard6, ICard7 } from '../../graphql/year-review.query'
import UnderlineAnimation from '../stateless/UnderlineAnimation'
import commentsBgPurple from '../../lotties/comments-green'
import LottieAnimation from '../Animations/LottieAnimation'
import { TypeAnimation } from 'react-type-animation'
import { useLayoutEffect, useMemo, useState } from 'react'
import Delay from '../stateless/Delay'
interface Props extends ICard6 {}

interface Props extends ICard7 {
  clientType: ClientTypeEnum
  color: [number, number, number]
}

const Comment = ({
  comment,
  onFinish
}: {
  comment: string
  onFinish: () => void
}) => (
  <Box
    textAlign="left"
    fontSize={['12px', '18px']}
    bg="white"
    boxShadow="0px 2px 6px rgba(0,0,0,.2)"
    p="12px 16px"
    w="100%"
    color="primary.100"
    borderRadius="5px"
    width="100%"
    transition="250ms linear all"
  >
    <TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        comment,
        2000, // wait 1s before replacing "Mice" with "Hamsters",
        () => {
          onFinish()
        }
      ]}
      speed={75}
      wrapper="span"
      // style={{ fontSize: '18px' }}
    />
  </Box>
)

const Comments = ({ comments }: { comments: string[] }) => {
  const [index, setIndex] = useState(0)

  return (
    <Box w="80%">
      <SlideFade key={`comments-${index}`} offsetY="20px" delay={0.5} in={true}>
        <Comment
          comment={comments[index]}
          onFinish={() => setIndex(e => (e == comments.length - 1 ? e : e + 1))}
        />
      </SlideFade>
    </Box>
  )
}
export default function Card7({
  approvalRate: happiness,
  clientType,
  color,
  comments = []
}: Props) {
  const _lottie = useMemo(() => commentsBgPurple(color), [])
  return (
    <Flex
      height="100%"
      w="100%"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box zIndex={1} w="100%" height="100%" position="absolute">
        <LottieAnimation height={'100%'} animation={_lottie} delay={250} />
      </Box>
      <Box height="360px"></Box>
      <Box
        zIndex={2}
        flex="1"
        display="flex"
        alignItems="start"
        justifyContent="center"
        w="100%"
      >
        <Delay delay={2}>
          <Comments comments={comments} />
        </Delay>
      </Box>

      <Box height="100px"></Box>
    </Flex>
  )
}
