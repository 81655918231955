export default (color: [number, number, number]) => ({
  assets: [
    {
      id: 'SiNw7-U2Yas9VeVjgVWTh',
      layers: [
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: '',
          ln: 'oRyIfR2Z9IS6tuBYsWsa37',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50013.28, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [2.3, -1.98],
                        [2.3, -1.98],
                        [2.48, -2.86],
                        [2.94, -3.58],
                        [3.65, -4.07],
                        [4.54, -4.25],
                        [4.54, -4.25],
                        [5.42, -4.07],
                        [6.14, -3.58],
                        [6.62, -2.86],
                        [6.79, -1.98],
                        [6.79, -1.98],
                        [6.62, -1.09],
                        [6.14, -0.38],
                        [5.42, 0.1],
                        [4.54, 0.27],
                        [4.54, 0.27],
                        [3.65, 0.1],
                        [2.94, -0.38],
                        [2.48, -1.09],
                        [2.3, -1.98]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.12, 0.27],
                        [-0.19, 0.2],
                        [-0.27, 0.12],
                        [-0.31, 0],
                        [0, 0],
                        [-0.28, -0.12],
                        [-0.21, -0.21],
                        [-0.12, -0.28],
                        [0, -0.31],
                        [0, 0],
                        [0.11, -0.27],
                        [0.2, -0.2],
                        [0.27, -0.11],
                        [0.31, 0],
                        [0, 0],
                        [0.28, 0.11],
                        [0.2, 0.21],
                        [0.11, 0.27],
                        [0, 0.33]
                      ],
                      o: [
                        [0, 0],
                        [0, -0.31],
                        [0.11, -0.28],
                        [0.2, -0.21],
                        [0.28, -0.12],
                        [0, 0],
                        [0.31, 0],
                        [0.27, 0.12],
                        [0.2, 0.2],
                        [0.11, 0.27],
                        [0, 0],
                        [0, 0.33],
                        [-0.12, 0.27],
                        [-0.21, 0.21],
                        [-0.28, 0.11],
                        [0, 0],
                        [-0.31, 0],
                        [-0.27, -0.11],
                        [-0.19, -0.2],
                        [-0.12, -0.27],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [2.3, -15.48],
                        [2.3, -15.48],
                        [2.48, -16.36],
                        [2.94, -17.08],
                        [3.65, -17.57],
                        [4.54, -17.75],
                        [4.54, -17.75],
                        [5.42, -17.57],
                        [6.14, -17.08],
                        [6.62, -16.36],
                        [6.79, -15.48],
                        [6.79, -15.48],
                        [6.62, -14.59],
                        [6.14, -13.88],
                        [5.42, -13.4],
                        [4.54, -13.23],
                        [4.54, -13.23],
                        [3.65, -13.4],
                        [2.94, -13.88],
                        [2.48, -14.59],
                        [2.3, -15.48]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.12, 0.27],
                        [-0.19, 0.2],
                        [-0.27, 0.12],
                        [-0.31, 0],
                        [0, 0],
                        [-0.28, -0.12],
                        [-0.21, -0.21],
                        [-0.12, -0.28],
                        [0, -0.31],
                        [0, 0],
                        [0.11, -0.27],
                        [0.2, -0.2],
                        [0.27, -0.11],
                        [0.31, 0],
                        [0, 0],
                        [0.28, 0.11],
                        [0.2, 0.21],
                        [0.11, 0.27],
                        [0, 0.33]
                      ],
                      o: [
                        [0, 0],
                        [0, -0.31],
                        [0.11, -0.28],
                        [0.2, -0.21],
                        [0.28, -0.12],
                        [0, 0],
                        [0.31, 0],
                        [0.27, 0.12],
                        [0.2, 0.2],
                        [0.11, 0.27],
                        [0, 0],
                        [0, 0.33],
                        [-0.12, 0.27],
                        [-0.21, 0.21],
                        [-0.28, 0.11],
                        [0, 0],
                        [-0.31, 0],
                        [-0.27, -0.11],
                        [-0.19, -0.2],
                        [-0.12, -0.27],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: '',
          ln: 'XSGqzqMBV-9PKRq8S03jh8',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49993.27, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [10.01, -18.52],
                        [10.01, -18.52],
                        [13.62, -17.86],
                        [16.35, -15.97],
                        [18.08, -13.01],
                        [18.68, -9.13],
                        [18.68, -9.13],
                        [18.08, -5.24],
                        [16.35, -2.29],
                        [13.62, -0.41],
                        [10.01, 0.25],
                        [10.01, 0.25],
                        [6.4, -0.41],
                        [3.65, -2.29],
                        [1.91, -5.24],
                        [1.3, -9.13],
                        [1.3, -9.13],
                        [1.91, -13.01],
                        [3.65, -15.97],
                        [6.4, -17.86],
                        [10.01, -18.52]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-1.07, -0.44],
                        [-0.75, -0.81],
                        [-0.4, -1.15],
                        [0, -1.43],
                        [0, 0],
                        [0.4, -1.15],
                        [0.75, -0.81],
                        [1.07, -0.43],
                        [1.33, 0],
                        [0, 0],
                        [1.07, 0.44],
                        [0.76, 0.82],
                        [0.41, 1.15],
                        [0, 1.44],
                        [0, 0],
                        [-0.41, 1.16],
                        [-0.75, 0.82],
                        [-1.07, 0.45],
                        [-1.33, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.33, 0],
                        [1.07, 0.45],
                        [0.75, 0.82],
                        [0.4, 1.16],
                        [0, 0],
                        [0, 1.44],
                        [-0.4, 1.15],
                        [-0.75, 0.82],
                        [-1.07, 0.44],
                        [0, 0],
                        [-1.33, 0],
                        [-1.07, -0.43],
                        [-0.75, -0.81],
                        [-0.41, -1.15],
                        [0, 0],
                        [0, -1.43],
                        [0.41, -1.15],
                        [0.76, -0.81],
                        [1.07, -0.44],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [10.01, -2.25],
                        [10.01, -2.25],
                        [14.04, -4.06],
                        [15.37, -9.11],
                        [15.37, -9.11],
                        [14.04, -14.18],
                        [10.01, -16],
                        [10.01, -16],
                        [7.63, -15.53],
                        [5.95, -14.18],
                        [4.94, -12.02],
                        [4.61, -9.11],
                        [4.61, -9.11],
                        [4.94, -6.21],
                        [5.95, -4.06],
                        [7.63, -2.72],
                        [10.01, -2.25]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.89, 1.21],
                        [0, 2.16],
                        [0, 0],
                        [0.89, 1.21],
                        [1.8, 0],
                        [0, 0],
                        [0.67, -0.31],
                        [0.45, -0.59],
                        [0.22, -0.85],
                        [0, -1.08],
                        [0, 0],
                        [-0.22, -0.85],
                        [-0.45, -0.58],
                        [-0.67, -0.31],
                        [-0.91, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.8, 0],
                        [0.89, -1.21],
                        [0, 0],
                        [0, -2.17],
                        [-0.89, -1.21],
                        [0, 0],
                        [-0.91, 0],
                        [-0.67, 0.31],
                        [-0.45, 0.59],
                        [-0.22, 0.86],
                        [0, 0],
                        [0, 1.08],
                        [0.22, 0.85],
                        [0.45, 0.58],
                        [0.67, 0.31],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [12.13, -26.08],
                        [15.26, -26.08],
                        [11.07, -21.44],
                        [10.58, -21.05],
                        [9.95, -20.93],
                        [9.95, -20.93],
                        [8.05, -20.93],
                        [10.71, -25.29],
                        [11.26, -25.89],
                        [12.13, -26.08],
                        [12.13, -26.08]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.16, -0.08],
                        [0.25, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.2, 0.13],
                        [-0.38, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.17, 0.18],
                        [-0.17, 0.08],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.17, -0.27],
                        [0.2, -0.13],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: '',
          ln: 'J5P1LTqhztWRPYQQQSwPB9',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49977.64, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [13.79, -16.4],
                        [13.07, -15.23],
                        [12.4, -14.83],
                        [12.4, -14.83],
                        [11.79, -15.03],
                        [10.95, -15.47],
                        [9.77, -15.92],
                        [8.15, -16.13],
                        [8.15, -16.13],
                        [6.7, -15.92],
                        [5.59, -15.35],
                        [4.89, -14.52],
                        [4.64, -13.48],
                        [4.64, -13.48],
                        [5.05, -12.31],
                        [6.12, -11.5],
                        [7.63, -10.9],
                        [9.37, -10.34],
                        [11.11, -9.68],
                        [12.62, -8.78],
                        [13.69, -7.46],
                        [14.09, -5.58],
                        [14.09, -5.58],
                        [13.64, -3.25],
                        [12.31, -1.4],
                        [10.15, -0.16],
                        [7.2, 0.29],
                        [7.2, 0.29],
                        [3.74, -0.33],
                        [1.12, -1.93],
                        [1.12, -1.93],
                        [1.87, -3.15],
                        [2.21, -3.51],
                        [2.74, -3.64],
                        [2.74, -3.64],
                        [3.42, -3.38],
                        [4.29, -2.83],
                        [5.54, -2.27],
                        [7.36, -2.02],
                        [7.36, -2.02],
                        [9, -2.26],
                        [10.17, -2.92],
                        [10.86, -3.87],
                        [11.09, -5.02],
                        [11.09, -5.02],
                        [10.68, -6.27],
                        [9.61, -7.12],
                        [8.09, -7.73],
                        [6.35, -8.28],
                        [4.6, -8.94],
                        [3.08, -9.86],
                        [2.01, -11.24],
                        [1.6, -13.23],
                        [1.6, -13.23],
                        [2.03, -15.24],
                        [3.29, -16.93],
                        [5.33, -18.09],
                        [8.08, -18.52],
                        [8.08, -18.52],
                        [11.31, -17.96],
                        [13.79, -16.4],
                        [13.79, -16.4]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.3, 0],
                        [0, 0],
                        [0.23, 0.13],
                        [0.33, 0.16],
                        [0.46, 0.14],
                        [0.63, 0],
                        [0, 0],
                        [0.43, -0.14],
                        [0.31, -0.24],
                        [0.16, -0.31],
                        [0, -0.37],
                        [0, 0],
                        [-0.27, -0.31],
                        [-0.45, -0.23],
                        [-0.56, -0.17],
                        [-0.59, -0.2],
                        [-0.57, -0.24],
                        [-0.45, -0.36],
                        [-0.27, -0.52],
                        [0, -0.73],
                        [0, 0],
                        [0.3, -0.71],
                        [0.59, -0.52],
                        [0.85, -0.3],
                        [1.11, 0],
                        [0, 0],
                        [1.03, 0.41],
                        [0.72, 0.65],
                        [0, 0],
                        [0, 0],
                        [-0.13, 0.09],
                        [-0.22, 0],
                        [0, 0],
                        [-0.24, -0.17],
                        [-0.34, -0.2],
                        [-0.49, -0.17],
                        [-0.73, 0],
                        [0, 0],
                        [-0.47, 0.16],
                        [-0.31, 0.28],
                        [-0.15, 0.36],
                        [0, 0.41],
                        [0, 0],
                        [0.27, 0.33],
                        [0.45, 0.23],
                        [0.57, 0.17],
                        [0.59, 0.19],
                        [0.57, 0.25],
                        [0.44, 0.37],
                        [0.27, 0.55],
                        [0, 0.78],
                        [0, 0],
                        [-0.29, 0.65],
                        [-0.55, 0.49],
                        [-0.81, 0.29],
                        [-1.03, 0],
                        [0, 0],
                        [-0.95, -0.37],
                        [-0.7, -0.66],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [-0.15, 0.27],
                        [0, 0],
                        [-0.18, 0],
                        [-0.23, -0.13],
                        [-0.33, -0.16],
                        [-0.45, -0.14],
                        [0, 0],
                        [-0.54, 0],
                        [-0.43, 0.14],
                        [-0.31, 0.24],
                        [-0.17, 0.32],
                        [0, 0],
                        [0, 0.47],
                        [0.27, 0.31],
                        [0.45, 0.23],
                        [0.57, 0.17],
                        [0.59, 0.2],
                        [0.56, 0.24],
                        [0.44, 0.36],
                        [0.27, 0.52],
                        [0, 0],
                        [0, 0.84],
                        [-0.3, 0.71],
                        [-0.59, 0.53],
                        [-0.85, 0.3],
                        [0, 0],
                        [-1.27, 0],
                        [-1.03, -0.41],
                        [0, 0],
                        [0, 0],
                        [0.1, -0.15],
                        [0.13, -0.09],
                        [0, 0],
                        [0.21, 0],
                        [0.24, 0.17],
                        [0.35, 0.21],
                        [0.48, 0.17],
                        [0, 0],
                        [0.63, 0],
                        [0.47, -0.16],
                        [0.31, -0.27],
                        [0.15, -0.36],
                        [0, 0],
                        [0, -0.51],
                        [-0.27, -0.33],
                        [-0.44, -0.23],
                        [-0.57, -0.17],
                        [-0.6, -0.19],
                        [-0.57, -0.25],
                        [-0.45, -0.37],
                        [-0.27, -0.55],
                        [0, 0],
                        [0, -0.69],
                        [0.29, -0.64],
                        [0.55, -0.49],
                        [0.8, -0.29],
                        [0, 0],
                        [1.2, 0],
                        [0.95, 0.38],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        }
      ]
    },
    {
      id: 'vNCZc-Ti8mBlTdCKvNfGH',
      layers: [
        {
          ddd: 0,
          ind: 6,
          ty: 0,
          nm: '',
          ln: 'precomp_lFVxKNJhpz2a9TUBgf_7U6',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: {
              a: 1,
              k: [
                { t: 0, s: [50000, 50000], h: 1 },
                {
                  t: 0,
                  s: [50000, 50043.2],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 0, s: [50000, 50043.2], h: 1 },
                {
                  t: 95,
                  s: [50000, 50043.2],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 120, s: [50000, 50000], h: 1 }
              ]
            },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'SiNw7-U2Yas9VeVjgVWTh'
        }
      ]
    },
    {
      id: 'sQMEhb6_5dSv0yBun2qbu',
      layers: [
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: '',
          td: 1,
          ln: 'hdeLzgOvJPX4rfMFUs20k10',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'rc',
                  hd: false,
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [131.11, 43.2] }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 5,
          ty: 0,
          nm: '',
          tt: 1,
          ln: 'precomp_UxdMKdRlL6KA7LNhXcQme5',
          sr: 1,
          ks: {
            a: { a: 0, k: [100000, 100000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [100000, 100000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'vNCZc-Ti8mBlTdCKvNfGH'
        }
      ]
    },
    {
      id: 'ZI8lJT4RYt-I4kX9toTH0',
      layers: [
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: '',
          ln: 'Xq_ltST2jL9s5QWyvo7A014',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50019.85, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [16.02, -11.66],
                        [16.02, 0],
                        [14.6, 0],
                        [13.84, -0.14],
                        [13.46, -0.76],
                        [13.46, -0.76],
                        [13.1, -2.45],
                        [11.7, -1.29],
                        [10.26, -0.42],
                        [8.65, 0.11],
                        [6.75, 0.29],
                        [6.75, 0.29],
                        [4.76, -0.01],
                        [3.15, -0.9],
                        [2.06, -2.4],
                        [1.66, -4.55],
                        [1.66, -4.55],
                        [2.25, -6.63],
                        [4.17, -8.41],
                        [7.63, -9.68],
                        [12.87, -10.24],
                        [12.87, -10.24],
                        [12.87, -11.66],
                        [11.96, -14.88],
                        [9.27, -15.97],
                        [9.27, -15.97],
                        [7.3, -15.67],
                        [5.91, -15],
                        [4.91, -14.34],
                        [4.07, -14.04],
                        [4.07, -14.04],
                        [3.5, -14.21],
                        [3.11, -14.63],
                        [3.11, -14.63],
                        [2.54, -15.66],
                        [5.8, -17.84],
                        [9.67, -18.56],
                        [9.67, -18.56],
                        [12.38, -18.05],
                        [14.38, -16.65],
                        [15.61, -14.47],
                        [16.02, -11.66],
                        [16.02, -11.66]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.19, 0.09],
                        [0.06, 0.31],
                        [0, 0],
                        [0, 0],
                        [0.45, -0.34],
                        [0.51, -0.23],
                        [0.57, -0.12],
                        [0.69, 0],
                        [0, 0],
                        [0.62, 0.2],
                        [0.45, 0.39],
                        [0.27, 0.61],
                        [0, 0.83],
                        [0, 0],
                        [-0.39, 0.67],
                        [-0.89, 0.52],
                        [-1.43, 0.33],
                        [-2.07, 0.05],
                        [0, 0],
                        [0, 0],
                        [0.61, 0.73],
                        [1.19, 0],
                        [0, 0],
                        [0.53, -0.2],
                        [0.39, -0.25],
                        [0.28, -0.19],
                        [0.27, 0],
                        [0, 0],
                        [0.16, 0.11],
                        [0.1, 0.17],
                        [0, 0],
                        [0, 0],
                        [-1.17, 0.48],
                        [-1.42, 0],
                        [0, 0],
                        [-0.79, -0.34],
                        [-0.54, -0.6],
                        [-0.28, -0.85],
                        [0, -1.02],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.31, 0],
                        [-0.19, -0.1],
                        [0, 0],
                        [0, 0],
                        [-0.48, 0.43],
                        [-0.45, 0.35],
                        [-0.51, 0.23],
                        [-0.57, 0.12],
                        [0, 0],
                        [-0.71, 0],
                        [-0.62, -0.2],
                        [-0.45, -0.39],
                        [-0.27, -0.61],
                        [0, 0],
                        [0, -0.72],
                        [0.39, -0.67],
                        [0.88, -0.51],
                        [1.43, -0.33],
                        [0, 0],
                        [0, 0],
                        [0, -1.42],
                        [-0.61, -0.73],
                        [0, 0],
                        [-0.78, 0],
                        [-0.53, 0.2],
                        [-0.39, 0.25],
                        [-0.29, 0.2],
                        [0, 0],
                        [-0.22, 0],
                        [-0.16, -0.11],
                        [0, 0],
                        [0, 0],
                        [1.01, -0.97],
                        [1.16, -0.48],
                        [0, 0],
                        [1.02, 0],
                        [0.79, 0.33],
                        [0.54, 0.6],
                        [0.27, 0.85],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [7.7, -1.96],
                        [7.7, -1.96],
                        [9.25, -2.13],
                        [10.58, -2.62],
                        [11.76, -3.38],
                        [12.87, -4.41],
                        [12.87, -4.41],
                        [12.87, -8.21],
                        [9.11, -7.86],
                        [6.59, -7.13],
                        [5.18, -6.07],
                        [4.73, -4.7],
                        [4.73, -4.7],
                        [4.97, -3.46],
                        [5.6, -2.6],
                        [6.53, -2.12],
                        [7.7, -1.96]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.47, 0.11],
                        [-0.42, 0.21],
                        [-0.37, 0.3],
                        [-0.36, 0.39],
                        [0, 0],
                        [0, 0],
                        [1.03, -0.19],
                        [0.65, -0.3],
                        [0.29, -0.41],
                        [0, -0.5],
                        [0, 0],
                        [-0.16, -0.35],
                        [-0.27, -0.22],
                        [-0.36, -0.1],
                        [-0.42, 0]
                      ],
                      o: [
                        [0, 0],
                        [0.57, 0],
                        [0.47, -0.11],
                        [0.41, -0.21],
                        [0.38, -0.3],
                        [0, 0],
                        [0, 0],
                        [-1.47, 0.05],
                        [-1.03, 0.19],
                        [-0.65, 0.3],
                        [-0.3, 0.41],
                        [0, 0],
                        [0, 0.48],
                        [0.15, 0.35],
                        [0.26, 0.22],
                        [0.36, 0.11],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: '',
          ln: 'yr3pQFntzQoKqQOIv_kNy15',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49999.84, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [5.83, 0],
                        [2.63, 0],
                        [2.63, -26.51],
                        [5.83, -26.51],
                        [5.83, -15.79],
                        [8.42, -17.78],
                        [11.7, -18.52],
                        [11.7, -18.52],
                        [14.34, -18.03],
                        [16.25, -16.62],
                        [17.41, -14.44],
                        [17.8, -11.61],
                        [17.8, -11.61],
                        [17.8, 0],
                        [14.6, 0],
                        [14.6, -11.61],
                        [13.65, -14.82],
                        [10.76, -15.97],
                        [10.76, -15.97],
                        [8.11, -15.28],
                        [5.83, -13.43],
                        [5.83, -13.43],
                        [5.83, 0]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.95, 0.5],
                        [-1.23, 0],
                        [0, 0],
                        [-0.77, -0.33],
                        [-0.51, -0.61],
                        [-0.27, -0.85],
                        [0, -1.03],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.63, 0.76],
                        [1.3, 0],
                        [0, 0],
                        [0.82, -0.46],
                        [0.7, -0.78],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.78, -0.83],
                        [0.95, -0.49],
                        [0, 0],
                        [0.99, 0],
                        [0.76, 0.33],
                        [0.51, 0.61],
                        [0.26, 0.85],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.38],
                        [-0.63, -0.77],
                        [0, 0],
                        [-0.95, 0],
                        [-0.82, 0.45],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: '',
          ln: 'tZ6MGybv2xH4VFx1bBd4N16',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49990.62, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [2.99, -26.51],
                        [6.19, -26.51],
                        [6.19, 0],
                        [2.99, 0],
                        [2.99, -26.51]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: '',
          ln: 'onhwmytQaTQPIJ_Bnlrzk17',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49961.89, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [27.09, -12.89],
                        [27.09, -12.89],
                        [26.17, -7.57],
                        [23.58, -3.4],
                        [19.56, -0.69],
                        [14.36, 0.27],
                        [14.36, 0.27],
                        [9.18, -0.69],
                        [5.17, -3.4],
                        [2.57, -7.57],
                        [1.66, -12.89],
                        [1.66, -12.89],
                        [2.57, -18.21],
                        [5.17, -22.38],
                        [9.18, -25.11],
                        [14.36, -26.08],
                        [14.36, -26.08],
                        [19.56, -25.11],
                        [23.58, -22.38],
                        [26.17, -18.21],
                        [27.09, -12.89]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.61, -1.61],
                        [1.11, -1.17],
                        [1.57, -0.65],
                        [1.9, 0],
                        [0, 0],
                        [1.56, 0.64],
                        [1.11, 1.16],
                        [0.61, 1.61],
                        [0, 1.93],
                        [0, 0],
                        [-0.61, 1.61],
                        [-1.12, 1.17],
                        [-1.56, 0.65],
                        [-1.89, 0],
                        [0, 0],
                        [-1.57, -0.65],
                        [-1.11, -1.17],
                        [-0.61, -1.61],
                        [0, -1.93]
                      ],
                      o: [
                        [0, 0],
                        [0, 1.93],
                        [-0.61, 1.61],
                        [-1.11, 1.16],
                        [-1.57, 0.64],
                        [0, 0],
                        [-1.89, 0],
                        [-1.56, -0.65],
                        [-1.12, -1.17],
                        [-0.61, -1.61],
                        [0, 0],
                        [0, -1.93],
                        [0.61, -1.61],
                        [1.11, -1.17],
                        [1.56, -0.65],
                        [0, 0],
                        [1.9, 0],
                        [1.57, 0.65],
                        [1.11, 1.17],
                        [0.61, 1.61],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [23.51, -12.89],
                        [23.51, -12.89],
                        [22.86, -17.15],
                        [21.02, -20.35],
                        [18.14, -22.36],
                        [14.36, -23.06],
                        [14.36, -23.06],
                        [10.6, -22.36],
                        [7.71, -20.35],
                        [5.87, -17.15],
                        [5.22, -12.89],
                        [5.22, -12.89],
                        [5.87, -8.63],
                        [7.71, -5.45],
                        [10.6, -3.45],
                        [14.36, -2.75],
                        [14.36, -2.75],
                        [18.14, -3.45],
                        [21.02, -5.45],
                        [22.86, -8.63],
                        [23.51, -12.89]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.43, 1.26],
                        [0.79, 0.87],
                        [1.13, 0.47],
                        [1.39, 0],
                        [0, 0],
                        [1.13, -0.47],
                        [0.8, -0.87],
                        [0.43, -1.26],
                        [0, -1.58],
                        [0, 0],
                        [-0.43, -1.25],
                        [-0.79, -0.87],
                        [-1.13, -0.46],
                        [-1.38, 0],
                        [0, 0],
                        [-1.13, 0.47],
                        [-0.79, 0.87],
                        [-0.43, 1.25],
                        [0, 1.59]
                      ],
                      o: [
                        [0, 0],
                        [0, -1.58],
                        [-0.43, -1.26],
                        [-0.79, -0.87],
                        [-1.13, -0.47],
                        [0, 0],
                        [-1.38, 0],
                        [-1.13, 0.47],
                        [-0.79, 0.87],
                        [-0.43, 1.26],
                        [0, 0],
                        [0, 1.59],
                        [0.43, 1.25],
                        [0.8, 0.87],
                        [1.13, 0.47],
                        [0, 0],
                        [1.39, 0],
                        [1.13, -0.46],
                        [0.79, -0.87],
                        [0.43, -1.25],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        }
      ]
    },
    {
      id: 'Q5V_-S-8LoNgYluIk7B2A',
      layers: [
        {
          ddd: 0,
          ind: 13,
          ty: 0,
          nm: '',
          ln: 'precomp_x49lQ4c1mlptgg45U3rqj13',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: {
              a: 1,
              k: [
                { t: 0, s: [50000, 50000], h: 1 },
                {
                  t: 0,
                  s: [50000, 50043.2],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 0, s: [50000, 50043.2], h: 1 },
                {
                  t: 95,
                  s: [50000, 50043.2],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 103, s: [50000, 50000], h: 1 }
              ]
            },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'ZI8lJT4RYt-I4kX9toTH0'
        }
      ]
    },
    {
      id: '2b4uRKZi5cP6tgA50brqV',
      layers: [
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: '',
          td: 1,
          ln: 'mqnU_6c3buHA_4m9g4Cku18',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'rc',
                  hd: false,
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [162.61, 43.2] }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 12,
          ty: 0,
          nm: '',
          tt: 1,
          ln: 'precomp_0LGqJenWdIJFF4UATnxpc12',
          sr: 1,
          ks: {
            a: { a: 0, k: [100000, 100000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [100000, 100000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'Q5V_-S-8LoNgYluIk7B2A'
        }
      ]
    },
    {
      id: '_cAFFPzs7CUSsu11B6cnl',
      layers: [
        {
          ddd: 0,
          ind: 4,
          ty: 0,
          nm: '',
          ln: 'precomp_UxdMKdRlL6KA7LNhXcQme4',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50041.58, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'sQMEhb6_5dSv0yBun2qbu'
        },
        {
          ddd: 0,
          ind: 11,
          ty: 0,
          nm: '',
          ln: 'precomp_0LGqJenWdIJFF4UATnxpc11',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49974.17, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: '2b4uRKZi5cP6tgA50brqV'
        }
      ]
    },
    {
      id: '6sAH_ioRzYp9_gTeCk9nR',
      layers: [
        {
          ddd: 0,
          ind: 3,
          ty: 0,
          nm: '',
          ln: 'precomp_C3oSEhhqS0IRFP8FBj0OS3',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: '_cAFFPzs7CUSsu11B6cnl'
        }
      ]
    },
    {
      id: 'lD_aczWon0MfiKz_nV7fs',
      layers: [
        {
          ddd: 0,
          ind: 24,
          ty: 4,
          nm: '',
          ln: 'rOt8JQRWVWIoU6HuZ5KMk24',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50094.34, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [1.58, -1.98],
                        [1.58, -1.98],
                        [1.76, -2.86],
                        [2.22, -3.58],
                        [2.93, -4.07],
                        [3.82, -4.25],
                        [3.82, -4.25],
                        [4.7, -4.07],
                        [5.42, -3.58],
                        [5.9, -2.86],
                        [6.07, -1.98],
                        [6.07, -1.98],
                        [5.9, -1.09],
                        [5.42, -0.38],
                        [4.7, 0.1],
                        [3.82, 0.27],
                        [3.82, 0.27],
                        [2.93, 0.1],
                        [2.22, -0.38],
                        [1.76, -1.09],
                        [1.58, -1.98]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.12, 0.27],
                        [-0.19, 0.2],
                        [-0.27, 0.12],
                        [-0.31, 0],
                        [0, 0],
                        [-0.28, -0.12],
                        [-0.21, -0.21],
                        [-0.12, -0.28],
                        [0, -0.31],
                        [0, 0],
                        [0.11, -0.27],
                        [0.2, -0.2],
                        [0.27, -0.11],
                        [0.31, 0],
                        [0, 0],
                        [0.28, 0.11],
                        [0.2, 0.21],
                        [0.11, 0.27],
                        [0, 0.33]
                      ],
                      o: [
                        [0, 0],
                        [0, -0.31],
                        [0.11, -0.28],
                        [0.2, -0.21],
                        [0.28, -0.12],
                        [0, 0],
                        [0.31, 0],
                        [0.27, 0.12],
                        [0.2, 0.2],
                        [0.11, 0.27],
                        [0, 0],
                        [0, 0.33],
                        [-0.12, 0.27],
                        [-0.21, 0.21],
                        [-0.28, 0.11],
                        [0, 0],
                        [-0.31, 0],
                        [-0.27, -0.11],
                        [-0.19, -0.2],
                        [-0.12, -0.27],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 25,
          ty: 4,
          nm: '',
          ln: 'BfGX6Gew76jerOgxIPjHl25',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50078.71, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [13.79, -16.4],
                        [13.07, -15.23],
                        [12.4, -14.83],
                        [12.4, -14.83],
                        [11.79, -15.03],
                        [10.95, -15.47],
                        [9.77, -15.92],
                        [8.15, -16.13],
                        [8.15, -16.13],
                        [6.7, -15.92],
                        [5.59, -15.35],
                        [4.89, -14.52],
                        [4.64, -13.48],
                        [4.64, -13.48],
                        [5.05, -12.31],
                        [6.12, -11.5],
                        [7.63, -10.9],
                        [9.37, -10.34],
                        [11.11, -9.68],
                        [12.62, -8.78],
                        [13.69, -7.46],
                        [14.09, -5.58],
                        [14.09, -5.58],
                        [13.64, -3.25],
                        [12.31, -1.4],
                        [10.15, -0.16],
                        [7.2, 0.29],
                        [7.2, 0.29],
                        [3.74, -0.33],
                        [1.12, -1.93],
                        [1.12, -1.93],
                        [1.87, -3.15],
                        [2.21, -3.51],
                        [2.74, -3.64],
                        [2.74, -3.64],
                        [3.42, -3.38],
                        [4.29, -2.83],
                        [5.54, -2.27],
                        [7.36, -2.02],
                        [7.36, -2.02],
                        [9, -2.26],
                        [10.17, -2.92],
                        [10.86, -3.87],
                        [11.09, -5.02],
                        [11.09, -5.02],
                        [10.68, -6.27],
                        [9.61, -7.12],
                        [8.09, -7.73],
                        [6.35, -8.28],
                        [4.6, -8.94],
                        [3.08, -9.86],
                        [2.01, -11.24],
                        [1.6, -13.23],
                        [1.6, -13.23],
                        [2.03, -15.24],
                        [3.29, -16.93],
                        [5.33, -18.09],
                        [8.08, -18.52],
                        [8.08, -18.52],
                        [11.31, -17.96],
                        [13.79, -16.4],
                        [13.79, -16.4]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.3, 0],
                        [0, 0],
                        [0.23, 0.13],
                        [0.33, 0.16],
                        [0.46, 0.14],
                        [0.63, 0],
                        [0, 0],
                        [0.43, -0.14],
                        [0.31, -0.24],
                        [0.16, -0.31],
                        [0, -0.37],
                        [0, 0],
                        [-0.27, -0.31],
                        [-0.45, -0.23],
                        [-0.56, -0.17],
                        [-0.59, -0.2],
                        [-0.57, -0.24],
                        [-0.45, -0.36],
                        [-0.27, -0.52],
                        [0, -0.73],
                        [0, 0],
                        [0.3, -0.71],
                        [0.59, -0.52],
                        [0.85, -0.3],
                        [1.11, 0],
                        [0, 0],
                        [1.03, 0.41],
                        [0.72, 0.65],
                        [0, 0],
                        [0, 0],
                        [-0.13, 0.09],
                        [-0.22, 0],
                        [0, 0],
                        [-0.24, -0.17],
                        [-0.34, -0.2],
                        [-0.49, -0.17],
                        [-0.73, 0],
                        [0, 0],
                        [-0.47, 0.16],
                        [-0.31, 0.28],
                        [-0.15, 0.36],
                        [0, 0.41],
                        [0, 0],
                        [0.27, 0.33],
                        [0.45, 0.23],
                        [0.57, 0.17],
                        [0.59, 0.19],
                        [0.57, 0.25],
                        [0.44, 0.37],
                        [0.27, 0.55],
                        [0, 0.78],
                        [0, 0],
                        [-0.29, 0.65],
                        [-0.55, 0.49],
                        [-0.81, 0.29],
                        [-1.03, 0],
                        [0, 0],
                        [-0.95, -0.37],
                        [-0.7, -0.66],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [-0.15, 0.27],
                        [0, 0],
                        [-0.18, 0],
                        [-0.23, -0.13],
                        [-0.33, -0.16],
                        [-0.45, -0.14],
                        [0, 0],
                        [-0.54, 0],
                        [-0.43, 0.14],
                        [-0.31, 0.24],
                        [-0.17, 0.32],
                        [0, 0],
                        [0, 0.47],
                        [0.27, 0.31],
                        [0.45, 0.23],
                        [0.57, 0.17],
                        [0.59, 0.2],
                        [0.56, 0.24],
                        [0.44, 0.36],
                        [0.27, 0.52],
                        [0, 0],
                        [0, 0.84],
                        [-0.3, 0.71],
                        [-0.59, 0.53],
                        [-0.85, 0.3],
                        [0, 0],
                        [-1.27, 0],
                        [-1.03, -0.41],
                        [0, 0],
                        [0, 0],
                        [0.1, -0.15],
                        [0.13, -0.09],
                        [0, 0],
                        [0.21, 0],
                        [0.24, 0.17],
                        [0.35, 0.21],
                        [0.48, 0.17],
                        [0, 0],
                        [0.63, 0],
                        [0.47, -0.16],
                        [0.31, -0.27],
                        [0.15, -0.36],
                        [0, 0],
                        [0, -0.51],
                        [-0.27, -0.33],
                        [-0.44, -0.23],
                        [-0.57, -0.17],
                        [-0.6, -0.19],
                        [-0.57, -0.25],
                        [-0.45, -0.37],
                        [-0.27, -0.55],
                        [0, 0],
                        [0, -0.69],
                        [0.29, -0.64],
                        [0.55, -0.49],
                        [0.8, -0.29],
                        [0, 0],
                        [1.2, 0],
                        [0.95, 0.38],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 26,
          ty: 4,
          nm: '',
          ln: '3xEE7ykM-0etBMs87drAq26',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50058.7, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [10.01, -18.52],
                        [10.01, -18.52],
                        [13.62, -17.86],
                        [16.35, -15.97],
                        [18.08, -13.01],
                        [18.68, -9.13],
                        [18.68, -9.13],
                        [18.08, -5.24],
                        [16.35, -2.29],
                        [13.62, -0.41],
                        [10.01, 0.25],
                        [10.01, 0.25],
                        [6.4, -0.41],
                        [3.65, -2.29],
                        [1.91, -5.24],
                        [1.3, -9.13],
                        [1.3, -9.13],
                        [1.91, -13.01],
                        [3.65, -15.97],
                        [6.4, -17.86],
                        [10.01, -18.52]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-1.07, -0.44],
                        [-0.75, -0.81],
                        [-0.4, -1.15],
                        [0, -1.43],
                        [0, 0],
                        [0.4, -1.15],
                        [0.75, -0.81],
                        [1.07, -0.43],
                        [1.33, 0],
                        [0, 0],
                        [1.07, 0.44],
                        [0.76, 0.82],
                        [0.41, 1.15],
                        [0, 1.44],
                        [0, 0],
                        [-0.41, 1.16],
                        [-0.75, 0.82],
                        [-1.07, 0.45],
                        [-1.33, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.33, 0],
                        [1.07, 0.45],
                        [0.75, 0.82],
                        [0.4, 1.16],
                        [0, 0],
                        [0, 1.44],
                        [-0.4, 1.15],
                        [-0.75, 0.82],
                        [-1.07, 0.44],
                        [0, 0],
                        [-1.33, 0],
                        [-1.07, -0.43],
                        [-0.75, -0.81],
                        [-0.41, -1.15],
                        [0, 0],
                        [0, -1.43],
                        [0.41, -1.15],
                        [0.76, -0.81],
                        [1.07, -0.44],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [10.01, -2.25],
                        [10.01, -2.25],
                        [14.04, -4.06],
                        [15.37, -9.11],
                        [15.37, -9.11],
                        [14.04, -14.18],
                        [10.01, -16],
                        [10.01, -16],
                        [7.63, -15.53],
                        [5.95, -14.18],
                        [4.94, -12.02],
                        [4.61, -9.11],
                        [4.61, -9.11],
                        [4.94, -6.21],
                        [5.95, -4.06],
                        [7.63, -2.72],
                        [10.01, -2.25]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.89, 1.21],
                        [0, 2.16],
                        [0, 0],
                        [0.89, 1.21],
                        [1.8, 0],
                        [0, 0],
                        [0.67, -0.31],
                        [0.45, -0.59],
                        [0.22, -0.85],
                        [0, -1.08],
                        [0, 0],
                        [-0.22, -0.85],
                        [-0.45, -0.58],
                        [-0.67, -0.31],
                        [-0.91, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.8, 0],
                        [0.89, -1.21],
                        [0, 0],
                        [0, -2.17],
                        [-0.89, -1.21],
                        [0, 0],
                        [-0.91, 0],
                        [-0.67, 0.31],
                        [-0.45, 0.59],
                        [-0.22, 0.86],
                        [0, 0],
                        [0, 1.08],
                        [0.22, 0.85],
                        [0.45, 0.58],
                        [0.67, 0.31],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 27,
          ty: 4,
          nm: '',
          ln: 'HEecs7dcK6KWsqKcudYoa27',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50049.48, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [2.99, -18.23],
                        [6.19, -18.23],
                        [6.19, 0],
                        [2.99, 0],
                        [2.99, -18.23]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [6.91, -23.96],
                        [6.91, -23.96],
                        [6.72, -23.09],
                        [6.22, -22.37],
                        [5.49, -21.87],
                        [4.61, -21.69],
                        [4.61, -21.69],
                        [3.74, -21.87],
                        [3.02, -22.37],
                        [2.52, -23.09],
                        [2.34, -23.96],
                        [2.34, -23.96],
                        [2.52, -24.85],
                        [3.02, -25.59],
                        [3.74, -26.08],
                        [4.61, -26.26],
                        [4.61, -26.26],
                        [5.49, -26.08],
                        [6.22, -25.59],
                        [6.72, -24.85],
                        [6.91, -23.96]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.13, -0.27],
                        [0.21, -0.21],
                        [0.27, -0.12],
                        [0.31, 0],
                        [0, 0],
                        [0.27, 0.12],
                        [0.21, 0.21],
                        [0.12, 0.27],
                        [0, 0.31],
                        [0, 0],
                        [-0.12, 0.28],
                        [-0.21, 0.21],
                        [-0.27, 0.12],
                        [-0.31, 0],
                        [0, 0],
                        [-0.27, -0.12],
                        [-0.21, -0.21],
                        [-0.13, -0.28],
                        [0, -0.31]
                      ],
                      o: [
                        [0, 0],
                        [0, 0.31],
                        [-0.13, 0.27],
                        [-0.21, 0.21],
                        [-0.27, 0.12],
                        [0, 0],
                        [-0.31, 0],
                        [-0.27, -0.12],
                        [-0.21, -0.21],
                        [-0.12, -0.27],
                        [0, 0],
                        [0, -0.31],
                        [0.12, -0.28],
                        [0.21, -0.21],
                        [0.27, -0.12],
                        [0, 0],
                        [0.31, 0],
                        [0.27, 0.12],
                        [0.21, 0.21],
                        [0.13, 0.28],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 28,
          ty: 4,
          nm: '',
          ln: 'bRdWXHXUBcxIvj3_nP12R28',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50034.97, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [5.83, 0],
                        [2.63, 0],
                        [2.63, -18.23],
                        [4.46, -18.23],
                        [5.18, -18.04],
                        [5.45, -17.35],
                        [5.45, -17.35],
                        [5.67, -14.51],
                        [7.98, -17.49],
                        [11.21, -18.56],
                        [11.21, -18.56],
                        [12.58, -18.39],
                        [13.72, -17.91],
                        [13.72, -17.91],
                        [13.3, -15.52],
                        [12.74, -15.07],
                        [12.74, -15.07],
                        [11.97, -15.24],
                        [10.51, -15.41],
                        [10.51, -15.41],
                        [7.71, -14.44],
                        [5.83, -11.61],
                        [5.83, -11.61],
                        [5.83, 0]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.13, -0.13],
                        [-0.05, -0.33],
                        [0, 0],
                        [0, 0],
                        [-0.91, 0.71],
                        [-1.23, 0],
                        [0, 0],
                        [-0.41, -0.11],
                        [-0.35, -0.21],
                        [0, 0],
                        [0, 0],
                        [0.29, 0],
                        [0, 0],
                        [0.35, 0.11],
                        [0.63, 0],
                        [0, 0],
                        [0.75, -0.65],
                        [0.51, -1.23],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.35, 0],
                        [0.13, 0.13],
                        [0, 0],
                        [0, 0],
                        [0.63, -1.27],
                        [0.92, -0.71],
                        [0, 0],
                        [0.51, 0],
                        [0.41, 0.11],
                        [0, 0],
                        [0, 0],
                        [-0.08, 0.3],
                        [0, 0],
                        [-0.17, 0],
                        [-0.35, -0.11],
                        [0, 0],
                        [-1.11, 0],
                        [-0.75, 0.65],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 29,
          ty: 4,
          nm: '',
          ln: 'qyxF6R754Pxlp4zbEhp2c29',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50016.72, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [16.02, -11.66],
                        [16.02, 0],
                        [14.6, 0],
                        [13.84, -0.14],
                        [13.46, -0.76],
                        [13.46, -0.76],
                        [13.1, -2.45],
                        [11.7, -1.29],
                        [10.26, -0.42],
                        [8.65, 0.11],
                        [6.75, 0.29],
                        [6.75, 0.29],
                        [4.76, -0.01],
                        [3.15, -0.9],
                        [2.06, -2.4],
                        [1.66, -4.55],
                        [1.66, -4.55],
                        [2.25, -6.63],
                        [4.17, -8.41],
                        [7.63, -9.68],
                        [12.87, -10.24],
                        [12.87, -10.24],
                        [12.87, -11.66],
                        [11.96, -14.88],
                        [9.27, -15.97],
                        [9.27, -15.97],
                        [7.3, -15.67],
                        [5.91, -15],
                        [4.91, -14.34],
                        [4.07, -14.04],
                        [4.07, -14.04],
                        [3.5, -14.21],
                        [3.11, -14.63],
                        [3.11, -14.63],
                        [2.54, -15.66],
                        [5.8, -17.84],
                        [9.67, -18.56],
                        [9.67, -18.56],
                        [12.38, -18.05],
                        [14.38, -16.65],
                        [15.61, -14.47],
                        [16.02, -11.66],
                        [16.02, -11.66]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.19, 0.09],
                        [0.06, 0.31],
                        [0, 0],
                        [0, 0],
                        [0.45, -0.34],
                        [0.51, -0.23],
                        [0.57, -0.12],
                        [0.69, 0],
                        [0, 0],
                        [0.62, 0.2],
                        [0.45, 0.39],
                        [0.27, 0.61],
                        [0, 0.83],
                        [0, 0],
                        [-0.39, 0.67],
                        [-0.89, 0.52],
                        [-1.43, 0.33],
                        [-2.07, 0.05],
                        [0, 0],
                        [0, 0],
                        [0.61, 0.73],
                        [1.19, 0],
                        [0, 0],
                        [0.53, -0.2],
                        [0.39, -0.25],
                        [0.28, -0.19],
                        [0.27, 0],
                        [0, 0],
                        [0.16, 0.11],
                        [0.1, 0.17],
                        [0, 0],
                        [0, 0],
                        [-1.17, 0.48],
                        [-1.42, 0],
                        [0, 0],
                        [-0.79, -0.34],
                        [-0.54, -0.6],
                        [-0.28, -0.85],
                        [0, -1.02],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.31, 0],
                        [-0.19, -0.1],
                        [0, 0],
                        [0, 0],
                        [-0.48, 0.43],
                        [-0.45, 0.35],
                        [-0.51, 0.23],
                        [-0.57, 0.12],
                        [0, 0],
                        [-0.71, 0],
                        [-0.62, -0.2],
                        [-0.45, -0.39],
                        [-0.27, -0.61],
                        [0, 0],
                        [0, -0.72],
                        [0.39, -0.67],
                        [0.88, -0.51],
                        [1.43, -0.33],
                        [0, 0],
                        [0, 0],
                        [0, -1.42],
                        [-0.61, -0.73],
                        [0, 0],
                        [-0.78, 0],
                        [-0.53, 0.2],
                        [-0.39, 0.25],
                        [-0.29, 0.2],
                        [0, 0],
                        [-0.22, 0],
                        [-0.16, -0.11],
                        [0, 0],
                        [0, 0],
                        [1.01, -0.97],
                        [1.16, -0.48],
                        [0, 0],
                        [1.02, 0],
                        [0.79, 0.33],
                        [0.54, 0.6],
                        [0.27, 0.85],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [7.7, -1.96],
                        [7.7, -1.96],
                        [9.25, -2.13],
                        [10.58, -2.62],
                        [11.76, -3.38],
                        [12.87, -4.41],
                        [12.87, -4.41],
                        [12.87, -8.21],
                        [9.11, -7.86],
                        [6.59, -7.13],
                        [5.18, -6.07],
                        [4.73, -4.7],
                        [4.73, -4.7],
                        [4.97, -3.46],
                        [5.6, -2.6],
                        [6.53, -2.12],
                        [7.7, -1.96]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.47, 0.11],
                        [-0.42, 0.21],
                        [-0.37, 0.3],
                        [-0.36, 0.39],
                        [0, 0],
                        [0, 0],
                        [1.03, -0.19],
                        [0.65, -0.3],
                        [0.29, -0.41],
                        [0, -0.5],
                        [0, 0],
                        [-0.16, -0.35],
                        [-0.27, -0.22],
                        [-0.36, -0.1],
                        [-0.42, 0]
                      ],
                      o: [
                        [0, 0],
                        [0.57, 0],
                        [0.47, -0.11],
                        [0.41, -0.21],
                        [0.38, -0.3],
                        [0, 0],
                        [0, 0],
                        [-1.47, 0.05],
                        [-1.03, 0.19],
                        [-0.65, 0.3],
                        [-0.3, 0.41],
                        [0, 0],
                        [0, 0.48],
                        [0.15, 0.35],
                        [0.26, 0.22],
                        [0.36, 0.11],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [11.59, -26.08],
                        [14.72, -26.08],
                        [10.53, -21.44],
                        [10.04, -21.05],
                        [9.41, -20.93],
                        [9.41, -20.93],
                        [7.51, -20.93],
                        [10.17, -25.29],
                        [10.72, -25.89],
                        [11.59, -26.08],
                        [11.59, -26.08]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.16, -0.08],
                        [0.25, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.2, 0.13],
                        [-0.38, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.17, 0.18],
                        [-0.17, 0.08],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.17, -0.27],
                        [0.2, -0.13],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 30,
          ty: 4,
          nm: '',
          ln: 'wr85SIqhyx4lk5-BVf4E030',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50003.29, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [8.15, 0.29],
                        [8.15, 0.29],
                        [4.83, -0.92],
                        [3.67, -4.39],
                        [3.67, -4.39],
                        [3.67, -15.55],
                        [1.48, -15.55],
                        [0.99, -15.72],
                        [0.79, -16.25],
                        [0.79, -16.25],
                        [0.79, -17.53],
                        [3.78, -17.91],
                        [4.52, -23.54],
                        [4.75, -23.99],
                        [5.26, -24.16],
                        [5.26, -24.16],
                        [6.88, -24.16],
                        [6.88, -17.87],
                        [12.1, -17.87],
                        [12.1, -15.55],
                        [6.88, -15.55],
                        [6.88, -4.61],
                        [7.43, -2.9],
                        [8.87, -2.34],
                        [8.87, -2.34],
                        [9.75, -2.48],
                        [10.39, -2.77],
                        [10.85, -3.07],
                        [11.18, -3.2],
                        [11.18, -3.2],
                        [11.63, -2.9],
                        [11.63, -2.9],
                        [12.56, -1.37],
                        [10.57, -0.15],
                        [8.15, 0.29]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.77, 0.81],
                        [0, 1.51],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.13, 0.11],
                        [0, 0.24],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.13, 0.12],
                        [-0.21, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.37, -0.37],
                        [-0.59, 0],
                        [0, 0],
                        [-0.25, 0.09],
                        [-0.18, 0.11],
                        [-0.13, 0.09],
                        [-0.1, 0],
                        [0, 0],
                        [-0.13, -0.2],
                        [0, 0],
                        [0, 0],
                        [0.78, -0.29],
                        [0.83, 0]
                      ],
                      o: [
                        [0, 0],
                        [-1.44, 0],
                        [-0.77, -0.8],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.19, 0],
                        [-0.13, -0.11],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.02, -0.18],
                        [0.13, -0.11],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.77],
                        [0.37, 0.37],
                        [0, 0],
                        [0.34, 0],
                        [0.25, -0.09],
                        [0.18, -0.11],
                        [0.12, -0.09],
                        [0, 0],
                        [0.17, 0],
                        [0, 0],
                        [0, 0],
                        [-0.55, 0.52],
                        [-0.78, 0.29],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 31,
          ty: 4,
          nm: '',
          ln: 'UncTj1p2X0HFpzysbF-Kh31',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49983.28, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [5.83, 0],
                        [2.63, 0],
                        [2.63, -18.23],
                        [4.54, -18.23],
                        [5.4, -17.57],
                        [5.4, -17.57],
                        [5.65, -15.59],
                        [8.31, -17.71],
                        [11.7, -18.52],
                        [11.7, -18.52],
                        [14.34, -18.03],
                        [16.25, -16.62],
                        [17.41, -14.44],
                        [17.8, -11.61],
                        [17.8, -11.61],
                        [17.8, 0],
                        [14.6, 0],
                        [14.6, -11.61],
                        [13.65, -14.82],
                        [10.76, -15.97],
                        [10.76, -15.97],
                        [8.11, -15.28],
                        [5.83, -13.43],
                        [5.83, -13.43],
                        [5.83, 0]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.12, -0.44],
                        [0, 0],
                        [0, 0],
                        [-0.98, 0.54],
                        [-1.29, 0],
                        [0, 0],
                        [-0.77, -0.33],
                        [-0.51, -0.61],
                        [-0.27, -0.85],
                        [0, -1.03],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.63, 0.76],
                        [1.3, 0],
                        [0, 0],
                        [0.82, -0.46],
                        [0.7, -0.78],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.45, 0],
                        [0, 0],
                        [0, 0],
                        [0.79, -0.87],
                        [0.97, -0.54],
                        [0, 0],
                        [0.99, 0],
                        [0.76, 0.33],
                        [0.51, 0.61],
                        [0.26, 0.85],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.38],
                        [-0.63, -0.77],
                        [0, 0],
                        [-0.95, 0],
                        [-0.82, 0.45],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 32,
          ty: 4,
          nm: '',
          ln: 'bUoHXC24VtTa-VsVB2ZwG32',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49964.41, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [9.85, -18.52],
                        [9.85, -18.52],
                        [12.87, -17.97],
                        [15.26, -16.39],
                        [16.84, -13.83],
                        [17.41, -10.37],
                        [17.41, -10.37],
                        [17.24, -9.36],
                        [16.63, -9.11],
                        [16.63, -9.11],
                        [4.5, -9.11],
                        [4.97, -6.1],
                        [6.16, -3.97],
                        [7.96, -2.69],
                        [10.3, -2.27],
                        [10.3, -2.27],
                        [12.38, -2.55],
                        [13.88, -3.15],
                        [14.93, -3.75],
                        [15.66, -4.03],
                        [15.66, -4.03],
                        [16.27, -3.73],
                        [16.27, -3.73],
                        [17.17, -2.56],
                        [15.75, -1.31],
                        [13.98, -0.43],
                        [12.02, 0.08],
                        [10.03, 0.25],
                        [10.03, 0.25],
                        [6.54, -0.39],
                        [3.79, -2.26],
                        [1.98, -5.31],
                        [1.33, -9.49],
                        [1.33, -9.49],
                        [1.92, -13.05],
                        [3.6, -15.92],
                        [6.28, -17.83],
                        [9.85, -18.52]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.93, -0.37],
                        [-0.67, -0.69],
                        [-0.38, -1.01],
                        [0, -1.29],
                        [0, 0],
                        [0.11, -0.17],
                        [0.3, 0],
                        [0, 0],
                        [0, 0],
                        [-0.29, -0.85],
                        [-0.51, -0.57],
                        [-0.7, -0.28],
                        [-0.87, 0],
                        [0, 0],
                        [-0.59, 0.19],
                        [-0.42, 0.21],
                        [-0.28, 0.19],
                        [-0.21, 0],
                        [0, 0],
                        [-0.14, -0.2],
                        [0, 0],
                        [0, 0],
                        [0.55, -0.35],
                        [0.63, -0.23],
                        [0.67, -0.11],
                        [0.66, 0],
                        [0, 0],
                        [1.07, 0.43],
                        [0.77, 0.82],
                        [0.43, 1.21],
                        [0, 1.57],
                        [0, 0],
                        [-0.39, 1.11],
                        [-0.73, 0.81],
                        [-1.05, 0.46],
                        [-1.32, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.09, 0],
                        [0.93, 0.37],
                        [0.67, 0.69],
                        [0.38, 1.01],
                        [0, 0],
                        [0, 0.51],
                        [-0.11, 0.17],
                        [0, 0],
                        [0, 0],
                        [0.03, 1.15],
                        [0.29, 0.85],
                        [0.5, 0.57],
                        [0.69, 0.28],
                        [0, 0],
                        [0.8, 0],
                        [0.58, -0.19],
                        [0.42, -0.21],
                        [0.28, -0.19],
                        [0, 0],
                        [0.27, 0],
                        [0, 0],
                        [0, 0],
                        [-0.39, 0.48],
                        [-0.55, 0.36],
                        [-0.63, 0.23],
                        [-0.67, 0.11],
                        [0, 0],
                        [-1.26, 0],
                        [-1.06, -0.43],
                        [-0.77, -0.82],
                        [-0.43, -1.21],
                        [0, 0],
                        [0, -1.27],
                        [0.39, -1.11],
                        [0.73, -0.81],
                        [1.06, -0.46],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [9.92, -16.16],
                        [9.92, -16.16],
                        [6.26, -14.82],
                        [4.61, -11.11],
                        [4.61, -11.11],
                        [14.53, -11.11],
                        [14.22, -13.15],
                        [13.32, -14.75],
                        [11.87, -15.8],
                        [9.92, -16.16]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.89, -0.89],
                        [0.21, -1.58],
                        [0, 0],
                        [0, 0],
                        [0.21, 0.62],
                        [0.39, 0.45],
                        [0.57, 0.25],
                        [0.73, 0]
                      ],
                      o: [
                        [0, 0],
                        [-1.55, 0],
                        [-0.89, 0.89],
                        [0, 0],
                        [0, 0],
                        [0, -0.74],
                        [-0.21, -0.62],
                        [-0.39, -0.45],
                        [-0.57, -0.24],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 33,
          ty: 4,
          nm: '',
          ln: 'JhNZe9mS2gRyKQgT9j-U333',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49934.86, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [5.83, 0],
                        [2.63, 0],
                        [2.63, -18.23],
                        [4.54, -18.23],
                        [5.4, -17.57],
                        [5.4, -17.57],
                        [5.63, -15.7],
                        [7.89, -17.73],
                        [10.8, -18.52],
                        [10.8, -18.52],
                        [13.8, -17.5],
                        [15.44, -14.72],
                        [15.44, -14.72],
                        [16.44, -16.43],
                        [17.84, -17.62],
                        [19.49, -18.31],
                        [21.26, -18.52],
                        [21.26, -18.52],
                        [23.82, -18.06],
                        [25.73, -16.72],
                        [26.93, -14.55],
                        [27.34, -11.61],
                        [27.34, -11.61],
                        [27.34, 0],
                        [24.14, 0],
                        [24.14, -11.61],
                        [23.2, -14.86],
                        [20.48, -15.97],
                        [20.48, -15.97],
                        [18.98, -15.69],
                        [17.73, -14.87],
                        [16.88, -13.51],
                        [16.56, -11.61],
                        [16.56, -11.61],
                        [16.56, 0],
                        [13.36, 0],
                        [13.36, -11.61],
                        [12.47, -14.89],
                        [9.9, -15.97],
                        [9.9, -15.97],
                        [7.7, -15.33],
                        [5.83, -13.59],
                        [5.83, -13.59],
                        [5.83, 0]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.12, -0.44],
                        [0, 0],
                        [0, 0],
                        [-0.83, 0.53],
                        [-1.11, 0],
                        [0, 0],
                        [-0.77, -0.68],
                        [-0.33, -1.17],
                        [0, 0],
                        [-0.41, 0.48],
                        [-0.52, 0.31],
                        [-0.59, 0.15],
                        [-0.6, 0],
                        [0, 0],
                        [-0.75, -0.31],
                        [-0.52, -0.59],
                        [-0.28, -0.86],
                        [0, -1.11],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.63, 0.74],
                        [1.19, 0],
                        [0, 0],
                        [0.47, -0.19],
                        [0.36, -0.36],
                        [0.21, -0.55],
                        [0, -0.72],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.59, 0.72],
                        [1.13, 0],
                        [0, 0],
                        [0.67, -0.43],
                        [0.57, -0.73],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.45, 0],
                        [0, 0],
                        [0, 0],
                        [0.67, -0.83],
                        [0.83, -0.53],
                        [0, 0],
                        [1.23, 0],
                        [0.76, 0.69],
                        [0, 0],
                        [0.25, -0.66],
                        [0.41, -0.48],
                        [0.51, -0.31],
                        [0.58, -0.14],
                        [0, 0],
                        [0.96, 0],
                        [0.75, 0.31],
                        [0.52, 0.59],
                        [0.27, 0.85],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.43],
                        [-0.62, -0.74],
                        [0, 0],
                        [-0.53, 0],
                        [-0.47, 0.19],
                        [-0.36, 0.36],
                        [-0.21, 0.55],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.47],
                        [-0.59, -0.72],
                        [0, 0],
                        [-0.79, 0],
                        [-0.68, 0.43],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 34,
          ty: 4,
          nm: '',
          ln: 'DrLuirnIXIGOuGJkipatu34',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49914.84, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [10.01, -18.52],
                        [10.01, -18.52],
                        [13.62, -17.86],
                        [16.35, -15.97],
                        [18.08, -13.01],
                        [18.68, -9.13],
                        [18.68, -9.13],
                        [18.08, -5.24],
                        [16.35, -2.29],
                        [13.62, -0.41],
                        [10.01, 0.25],
                        [10.01, 0.25],
                        [6.4, -0.41],
                        [3.65, -2.29],
                        [1.91, -5.24],
                        [1.3, -9.13],
                        [1.3, -9.13],
                        [1.91, -13.01],
                        [3.65, -15.97],
                        [6.4, -17.86],
                        [10.01, -18.52]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-1.07, -0.44],
                        [-0.75, -0.81],
                        [-0.4, -1.15],
                        [0, -1.43],
                        [0, 0],
                        [0.4, -1.15],
                        [0.75, -0.81],
                        [1.07, -0.43],
                        [1.33, 0],
                        [0, 0],
                        [1.07, 0.44],
                        [0.76, 0.82],
                        [0.41, 1.15],
                        [0, 1.44],
                        [0, 0],
                        [-0.41, 1.16],
                        [-0.75, 0.82],
                        [-1.07, 0.45],
                        [-1.33, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.33, 0],
                        [1.07, 0.45],
                        [0.75, 0.82],
                        [0.4, 1.16],
                        [0, 0],
                        [0, 1.44],
                        [-0.4, 1.15],
                        [-0.75, 0.82],
                        [-1.07, 0.44],
                        [0, 0],
                        [-1.33, 0],
                        [-1.07, -0.43],
                        [-0.75, -0.81],
                        [-0.41, -1.15],
                        [0, 0],
                        [0, -1.43],
                        [0.41, -1.15],
                        [0.76, -0.81],
                        [1.07, -0.44],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [10.01, -2.25],
                        [10.01, -2.25],
                        [14.04, -4.06],
                        [15.37, -9.11],
                        [15.37, -9.11],
                        [14.04, -14.18],
                        [10.01, -16],
                        [10.01, -16],
                        [7.63, -15.53],
                        [5.95, -14.18],
                        [4.94, -12.02],
                        [4.61, -9.11],
                        [4.61, -9.11],
                        [4.94, -6.21],
                        [5.95, -4.06],
                        [7.63, -2.72],
                        [10.01, -2.25]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.89, 1.21],
                        [0, 2.16],
                        [0, 0],
                        [0.89, 1.21],
                        [1.8, 0],
                        [0, 0],
                        [0.67, -0.31],
                        [0.45, -0.59],
                        [0.22, -0.85],
                        [0, -1.08],
                        [0, 0],
                        [-0.22, -0.85],
                        [-0.45, -0.58],
                        [-0.67, -0.31],
                        [-0.91, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.8, 0],
                        [0.89, -1.21],
                        [0, 0],
                        [0, -2.17],
                        [-0.89, -1.21],
                        [0, 0],
                        [-0.91, 0],
                        [-0.67, 0.31],
                        [-0.45, 0.59],
                        [-0.22, 0.86],
                        [0, 0],
                        [0, 1.08],
                        [0.22, 0.85],
                        [0.45, 0.58],
                        [0.67, 0.31],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 35,
          ty: 4,
          nm: '',
          ln: '_Dg7IX3WuCz6f0O8TskV_35',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49898.03, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [15.91, -16.15],
                        [15.07, -14.99],
                        [14.78, -14.69],
                        [14.36, -14.58],
                        [14.36, -14.58],
                        [13.78, -14.81],
                        [12.98, -15.3],
                        [11.8, -15.8],
                        [10.1, -16.02],
                        [10.1, -16.02],
                        [7.74, -15.54],
                        [6.02, -14.17],
                        [4.98, -11.99],
                        [4.63, -9.13],
                        [4.63, -9.13],
                        [5, -6.18],
                        [6.07, -4.02],
                        [7.73, -2.69],
                        [9.94, -2.23],
                        [9.94, -2.23],
                        [11.86, -2.51],
                        [13.12, -3.13],
                        [13.95, -3.75],
                        [14.6, -4.03],
                        [14.6, -4.03],
                        [15.21, -3.73],
                        [15.21, -3.73],
                        [16.11, -2.56],
                        [13.14, -0.42],
                        [9.38, 0.25],
                        [9.38, 0.25],
                        [6.2, -0.38],
                        [3.65, -2.21],
                        [1.95, -5.15],
                        [1.33, -9.13],
                        [1.33, -9.13],
                        [1.9, -12.89],
                        [3.56, -15.87],
                        [6.25, -17.82],
                        [9.92, -18.52],
                        [9.92, -18.52],
                        [13.3, -17.9],
                        [15.91, -16.15],
                        [15.91, -16.15]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.09, -0.07],
                        [0.18, 0],
                        [0, 0],
                        [0.21, 0.15],
                        [0.32, 0.18],
                        [0.46, 0.15],
                        [0.67, 0],
                        [0, 0],
                        [0.69, -0.32],
                        [0.46, -0.6],
                        [0.23, -0.85],
                        [0, -1.05],
                        [0, 0],
                        [-0.25, -0.86],
                        [-0.46, -0.59],
                        [-0.65, -0.31],
                        [-0.82, 0],
                        [0, 0],
                        [-0.5, 0.19],
                        [-0.33, 0.23],
                        [-0.21, 0.19],
                        [-0.22, 0],
                        [0, 0],
                        [-0.13, -0.2],
                        [0, 0],
                        [0, 0],
                        [1.19, -0.45],
                        [1.32, 0],
                        [0, 0],
                        [0.98, 0.42],
                        [0.72, 0.8],
                        [0.41, 1.17],
                        [0, 1.49],
                        [0, 0],
                        [-0.38, 1.15],
                        [-0.73, 0.83],
                        [-1.07, 0.47],
                        [-1.38, 0],
                        [0, 0],
                        [-0.98, -0.41],
                        [-0.75, -0.75],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [-0.1, 0.13],
                        [-0.1, 0.07],
                        [0, 0],
                        [-0.18, 0],
                        [-0.21, -0.15],
                        [-0.33, -0.18],
                        [-0.46, -0.15],
                        [0, 0],
                        [-0.89, 0],
                        [-0.69, 0.31],
                        [-0.46, 0.6],
                        [-0.23, 0.85],
                        [0, 0],
                        [0, 1.11],
                        [0.25, 0.85],
                        [0.45, 0.58],
                        [0.65, 0.31],
                        [0, 0],
                        [0.78, 0],
                        [0.51, -0.19],
                        [0.34, -0.23],
                        [0.21, -0.19],
                        [0, 0],
                        [0.27, 0],
                        [0, 0],
                        [0, 0],
                        [-0.79, 0.97],
                        [-1.19, 0.45],
                        [0, 0],
                        [-1.14, 0],
                        [-0.98, -0.42],
                        [-0.72, -0.79],
                        [-0.41, -1.16],
                        [0, 0],
                        [0, -1.35],
                        [0.38, -1.15],
                        [0.72, -0.83],
                        [1.07, -0.47],
                        [0, 0],
                        [1.27, 0],
                        [0.99, 0.41],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        }
      ]
    },
    {
      id: 'IRxBtWQ1Zy6zwxMb_cRq6',
      layers: [
        {
          ddd: 0,
          ind: 23,
          ty: 0,
          nm: '',
          ln: 'precomp_qJoqEZOhx9lngNXhhM7ZV23',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: {
              a: 1,
              k: [
                { t: 0, s: [50000, 50000], h: 1 },
                {
                  t: 0,
                  s: [50000, 50043.2],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 0, s: [50000, 50043.2], h: 1 },
                {
                  t: 54,
                  s: [50000, 50043.2],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 84, s: [50000, 50000], h: 1 }
              ]
            },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'lD_aczWon0MfiKz_nV7fs'
        }
      ]
    },
    {
      id: 'jea_x4dQCnhglhL16V71f',
      layers: [
        {
          ddd: 0,
          ind: 36,
          ty: 4,
          nm: '',
          td: 1,
          ln: '7m2nX4aVIcuHeH0tLWmZw36',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'rc',
                  hd: false,
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [290.34, 43.2] }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 22,
          ty: 0,
          nm: '',
          tt: 1,
          ln: 'precomp_lXjDADwtH4r7aXDB6t9Qo22',
          sr: 1,
          ks: {
            a: { a: 0, k: [100000, 100000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [100000, 100000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'IRxBtWQ1Zy6zwxMb_cRq6'
        }
      ]
    },
    {
      id: 'zQXmk1NP95ol9SIaksew1',
      layers: [
        {
          ddd: 0,
          ind: 40,
          ty: 4,
          nm: '',
          ln: '8VYfpRxteeGxoF8U4ro6G40',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50043.56, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [5.83, 0],
                        [2.63, 0],
                        [2.63, -18.23],
                        [4.54, -18.23],
                        [5.4, -17.57],
                        [5.4, -17.57],
                        [5.63, -15.7],
                        [7.89, -17.73],
                        [10.8, -18.52],
                        [10.8, -18.52],
                        [13.8, -17.5],
                        [15.44, -14.72],
                        [15.44, -14.72],
                        [16.44, -16.43],
                        [17.84, -17.62],
                        [19.49, -18.31],
                        [21.26, -18.52],
                        [21.26, -18.52],
                        [23.82, -18.06],
                        [25.73, -16.72],
                        [26.93, -14.55],
                        [27.34, -11.61],
                        [27.34, -11.61],
                        [27.34, 0],
                        [24.14, 0],
                        [24.14, -11.61],
                        [23.2, -14.86],
                        [20.48, -15.97],
                        [20.48, -15.97],
                        [18.98, -15.69],
                        [17.73, -14.87],
                        [16.88, -13.51],
                        [16.56, -11.61],
                        [16.56, -11.61],
                        [16.56, 0],
                        [13.36, 0],
                        [13.36, -11.61],
                        [12.47, -14.89],
                        [9.9, -15.97],
                        [9.9, -15.97],
                        [7.7, -15.33],
                        [5.83, -13.59],
                        [5.83, -13.59],
                        [5.83, 0]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.12, -0.44],
                        [0, 0],
                        [0, 0],
                        [-0.83, 0.53],
                        [-1.11, 0],
                        [0, 0],
                        [-0.77, -0.68],
                        [-0.33, -1.17],
                        [0, 0],
                        [-0.41, 0.48],
                        [-0.52, 0.31],
                        [-0.59, 0.15],
                        [-0.6, 0],
                        [0, 0],
                        [-0.75, -0.31],
                        [-0.52, -0.59],
                        [-0.28, -0.86],
                        [0, -1.11],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.63, 0.74],
                        [1.19, 0],
                        [0, 0],
                        [0.47, -0.19],
                        [0.36, -0.36],
                        [0.21, -0.55],
                        [0, -0.72],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.59, 0.72],
                        [1.13, 0],
                        [0, 0],
                        [0.67, -0.43],
                        [0.57, -0.73],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.45, 0],
                        [0, 0],
                        [0, 0],
                        [0.67, -0.83],
                        [0.83, -0.53],
                        [0, 0],
                        [1.23, 0],
                        [0.76, 0.69],
                        [0, 0],
                        [0.25, -0.66],
                        [0.41, -0.48],
                        [0.51, -0.31],
                        [0.58, -0.14],
                        [0, 0],
                        [0.96, 0],
                        [0.75, 0.31],
                        [0.52, 0.59],
                        [0.27, 0.85],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.43],
                        [-0.62, -0.74],
                        [0, 0],
                        [-0.53, 0],
                        [-0.47, 0.19],
                        [-0.36, 0.36],
                        [-0.21, 0.55],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.47],
                        [-0.59, -0.72],
                        [0, 0],
                        [-0.79, 0],
                        [-0.68, 0.43],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 41,
          ty: 4,
          nm: '',
          ln: 'KAGJASU3Sq8bp21TxGNHq41',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50025.31, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [16.02, -11.66],
                        [16.02, 0],
                        [14.6, 0],
                        [13.84, -0.14],
                        [13.46, -0.76],
                        [13.46, -0.76],
                        [13.1, -2.45],
                        [11.7, -1.29],
                        [10.26, -0.42],
                        [8.65, 0.11],
                        [6.75, 0.29],
                        [6.75, 0.29],
                        [4.76, -0.01],
                        [3.15, -0.9],
                        [2.06, -2.4],
                        [1.66, -4.55],
                        [1.66, -4.55],
                        [2.25, -6.63],
                        [4.17, -8.41],
                        [7.63, -9.68],
                        [12.87, -10.24],
                        [12.87, -10.24],
                        [12.87, -11.66],
                        [11.96, -14.88],
                        [9.27, -15.97],
                        [9.27, -15.97],
                        [7.3, -15.67],
                        [5.91, -15],
                        [4.91, -14.34],
                        [4.07, -14.04],
                        [4.07, -14.04],
                        [3.5, -14.21],
                        [3.11, -14.63],
                        [3.11, -14.63],
                        [2.54, -15.66],
                        [5.8, -17.84],
                        [9.67, -18.56],
                        [9.67, -18.56],
                        [12.38, -18.05],
                        [14.38, -16.65],
                        [15.61, -14.47],
                        [16.02, -11.66],
                        [16.02, -11.66]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.19, 0.09],
                        [0.06, 0.31],
                        [0, 0],
                        [0, 0],
                        [0.45, -0.34],
                        [0.51, -0.23],
                        [0.57, -0.12],
                        [0.69, 0],
                        [0, 0],
                        [0.62, 0.2],
                        [0.45, 0.39],
                        [0.27, 0.61],
                        [0, 0.83],
                        [0, 0],
                        [-0.39, 0.67],
                        [-0.89, 0.52],
                        [-1.43, 0.33],
                        [-2.07, 0.05],
                        [0, 0],
                        [0, 0],
                        [0.61, 0.73],
                        [1.19, 0],
                        [0, 0],
                        [0.53, -0.2],
                        [0.39, -0.25],
                        [0.28, -0.19],
                        [0.27, 0],
                        [0, 0],
                        [0.16, 0.11],
                        [0.1, 0.17],
                        [0, 0],
                        [0, 0],
                        [-1.17, 0.48],
                        [-1.42, 0],
                        [0, 0],
                        [-0.79, -0.34],
                        [-0.54, -0.6],
                        [-0.28, -0.85],
                        [0, -1.02],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.31, 0],
                        [-0.19, -0.1],
                        [0, 0],
                        [0, 0],
                        [-0.48, 0.43],
                        [-0.45, 0.35],
                        [-0.51, 0.23],
                        [-0.57, 0.12],
                        [0, 0],
                        [-0.71, 0],
                        [-0.62, -0.2],
                        [-0.45, -0.39],
                        [-0.27, -0.61],
                        [0, 0],
                        [0, -0.72],
                        [0.39, -0.67],
                        [0.88, -0.51],
                        [1.43, -0.33],
                        [0, 0],
                        [0, 0],
                        [0, -1.42],
                        [-0.61, -0.73],
                        [0, 0],
                        [-0.78, 0],
                        [-0.53, 0.2],
                        [-0.39, 0.25],
                        [-0.29, 0.2],
                        [0, 0],
                        [-0.22, 0],
                        [-0.16, -0.11],
                        [0, 0],
                        [0, 0],
                        [1.01, -0.97],
                        [1.16, -0.48],
                        [0, 0],
                        [1.02, 0],
                        [0.79, 0.33],
                        [0.54, 0.6],
                        [0.27, 0.85],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [7.7, -1.96],
                        [7.7, -1.96],
                        [9.25, -2.13],
                        [10.58, -2.62],
                        [11.76, -3.38],
                        [12.87, -4.41],
                        [12.87, -4.41],
                        [12.87, -8.21],
                        [9.11, -7.86],
                        [6.59, -7.13],
                        [5.18, -6.07],
                        [4.73, -4.7],
                        [4.73, -4.7],
                        [4.97, -3.46],
                        [5.6, -2.6],
                        [6.53, -2.12],
                        [7.7, -1.96]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.47, 0.11],
                        [-0.42, 0.21],
                        [-0.37, 0.3],
                        [-0.36, 0.39],
                        [0, 0],
                        [0, 0],
                        [1.03, -0.19],
                        [0.65, -0.3],
                        [0.29, -0.41],
                        [0, -0.5],
                        [0, 0],
                        [-0.16, -0.35],
                        [-0.27, -0.22],
                        [-0.36, -0.1],
                        [-0.42, 0]
                      ],
                      o: [
                        [0, 0],
                        [0.57, 0],
                        [0.47, -0.11],
                        [0.41, -0.21],
                        [0.38, -0.3],
                        [0, 0],
                        [0, 0],
                        [-1.47, 0.05],
                        [-1.03, 0.19],
                        [-0.65, 0.3],
                        [-0.3, 0.41],
                        [0, 0],
                        [0, 0.48],
                        [0.15, 0.35],
                        [0.26, 0.22],
                        [0.36, 0.11],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 42,
          ty: 4,
          nm: '',
          ln: 'kRtip_NObcydSNpaNflQf42',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50011.48, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [5.83, 0],
                        [2.63, 0],
                        [2.63, -18.23],
                        [4.46, -18.23],
                        [5.18, -18.04],
                        [5.45, -17.35],
                        [5.45, -17.35],
                        [5.67, -14.51],
                        [7.98, -17.49],
                        [11.21, -18.56],
                        [11.21, -18.56],
                        [12.58, -18.39],
                        [13.72, -17.91],
                        [13.72, -17.91],
                        [13.3, -15.52],
                        [12.74, -15.07],
                        [12.74, -15.07],
                        [11.97, -15.24],
                        [10.51, -15.41],
                        [10.51, -15.41],
                        [7.71, -14.44],
                        [5.83, -11.61],
                        [5.83, -11.61],
                        [5.83, 0]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.13, -0.13],
                        [-0.05, -0.33],
                        [0, 0],
                        [0, 0],
                        [-0.91, 0.71],
                        [-1.23, 0],
                        [0, 0],
                        [-0.41, -0.11],
                        [-0.35, -0.21],
                        [0, 0],
                        [0, 0],
                        [0.29, 0],
                        [0, 0],
                        [0.35, 0.11],
                        [0.63, 0],
                        [0, 0],
                        [0.75, -0.65],
                        [0.51, -1.23],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.35, 0],
                        [0.13, 0.13],
                        [0, 0],
                        [0, 0],
                        [0.63, -1.27],
                        [0.92, -0.71],
                        [0, 0],
                        [0.51, 0],
                        [0.41, 0.11],
                        [0, 0],
                        [0, 0],
                        [-0.08, 0.3],
                        [0, 0],
                        [-0.17, 0],
                        [-0.35, -0.11],
                        [0, 0],
                        [-1.11, 0],
                        [-0.75, 0.65],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 43,
          ty: 4,
          nm: '',
          ln: 'P2ul0KgQYNLx7hVijCowK43',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49993.23, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [16.02, -11.66],
                        [16.02, 0],
                        [14.6, 0],
                        [13.84, -0.14],
                        [13.46, -0.76],
                        [13.46, -0.76],
                        [13.1, -2.45],
                        [11.7, -1.29],
                        [10.26, -0.42],
                        [8.65, 0.11],
                        [6.75, 0.29],
                        [6.75, 0.29],
                        [4.76, -0.01],
                        [3.15, -0.9],
                        [2.06, -2.4],
                        [1.66, -4.55],
                        [1.66, -4.55],
                        [2.25, -6.63],
                        [4.17, -8.41],
                        [7.63, -9.68],
                        [12.87, -10.24],
                        [12.87, -10.24],
                        [12.87, -11.66],
                        [11.96, -14.88],
                        [9.27, -15.97],
                        [9.27, -15.97],
                        [7.3, -15.67],
                        [5.91, -15],
                        [4.91, -14.34],
                        [4.07, -14.04],
                        [4.07, -14.04],
                        [3.5, -14.21],
                        [3.11, -14.63],
                        [3.11, -14.63],
                        [2.54, -15.66],
                        [5.8, -17.84],
                        [9.67, -18.56],
                        [9.67, -18.56],
                        [12.38, -18.05],
                        [14.38, -16.65],
                        [15.61, -14.47],
                        [16.02, -11.66],
                        [16.02, -11.66]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.19, 0.09],
                        [0.06, 0.31],
                        [0, 0],
                        [0, 0],
                        [0.45, -0.34],
                        [0.51, -0.23],
                        [0.57, -0.12],
                        [0.69, 0],
                        [0, 0],
                        [0.62, 0.2],
                        [0.45, 0.39],
                        [0.27, 0.61],
                        [0, 0.83],
                        [0, 0],
                        [-0.39, 0.67],
                        [-0.89, 0.52],
                        [-1.43, 0.33],
                        [-2.07, 0.05],
                        [0, 0],
                        [0, 0],
                        [0.61, 0.73],
                        [1.19, 0],
                        [0, 0],
                        [0.53, -0.2],
                        [0.39, -0.25],
                        [0.28, -0.19],
                        [0.27, 0],
                        [0, 0],
                        [0.16, 0.11],
                        [0.1, 0.17],
                        [0, 0],
                        [0, 0],
                        [-1.17, 0.48],
                        [-1.42, 0],
                        [0, 0],
                        [-0.79, -0.34],
                        [-0.54, -0.6],
                        [-0.28, -0.85],
                        [0, -1.02],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.31, 0],
                        [-0.19, -0.1],
                        [0, 0],
                        [0, 0],
                        [-0.48, 0.43],
                        [-0.45, 0.35],
                        [-0.51, 0.23],
                        [-0.57, 0.12],
                        [0, 0],
                        [-0.71, 0],
                        [-0.62, -0.2],
                        [-0.45, -0.39],
                        [-0.27, -0.61],
                        [0, 0],
                        [0, -0.72],
                        [0.39, -0.67],
                        [0.88, -0.51],
                        [1.43, -0.33],
                        [0, 0],
                        [0, 0],
                        [0, -1.42],
                        [-0.61, -0.73],
                        [0, 0],
                        [-0.78, 0],
                        [-0.53, 0.2],
                        [-0.39, 0.25],
                        [-0.29, 0.2],
                        [0, 0],
                        [-0.22, 0],
                        [-0.16, -0.11],
                        [0, 0],
                        [0, 0],
                        [1.01, -0.97],
                        [1.16, -0.48],
                        [0, 0],
                        [1.02, 0],
                        [0.79, 0.33],
                        [0.54, 0.6],
                        [0.27, 0.85],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [7.7, -1.96],
                        [7.7, -1.96],
                        [9.25, -2.13],
                        [10.58, -2.62],
                        [11.76, -3.38],
                        [12.87, -4.41],
                        [12.87, -4.41],
                        [12.87, -8.21],
                        [9.11, -7.86],
                        [6.59, -7.13],
                        [5.18, -6.07],
                        [4.73, -4.7],
                        [4.73, -4.7],
                        [4.97, -3.46],
                        [5.6, -2.6],
                        [6.53, -2.12],
                        [7.7, -1.96]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.47, 0.11],
                        [-0.42, 0.21],
                        [-0.37, 0.3],
                        [-0.36, 0.39],
                        [0, 0],
                        [0, 0],
                        [1.03, -0.19],
                        [0.65, -0.3],
                        [0.29, -0.41],
                        [0, -0.5],
                        [0, 0],
                        [-0.16, -0.35],
                        [-0.27, -0.22],
                        [-0.36, -0.1],
                        [-0.42, 0]
                      ],
                      o: [
                        [0, 0],
                        [0.57, 0],
                        [0.47, -0.11],
                        [0.41, -0.21],
                        [0.38, -0.3],
                        [0, 0],
                        [0, 0],
                        [-1.47, 0.05],
                        [-1.03, 0.19],
                        [-0.65, 0.3],
                        [-0.3, 0.41],
                        [0, 0],
                        [0, 0.48],
                        [0.15, 0.35],
                        [0.26, 0.22],
                        [0.36, 0.11],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 44,
          ty: 4,
          nm: '',
          ln: 'UW5V_SSRvSCJIFyUFSdfq44',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49975.09, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [0.5, 0],
                        [6.89, -9.34],
                        [0.76, -18.23],
                        [3.83, -18.23],
                        [4.41, -18.11],
                        [4.73, -17.75],
                        [4.73, -17.75],
                        [9.2, -10.91],
                        [9.67, -11.92],
                        [9.67, -11.92],
                        [13.59, -17.68],
                        [13.95, -18.08],
                        [14.4, -18.23],
                        [14.4, -18.23],
                        [17.35, -18.23],
                        [11.21, -9.52],
                        [17.6, 0],
                        [14.53, 0],
                        [13.91, -0.21],
                        [13.54, -0.67],
                        [13.54, -0.67],
                        [8.95, -7.81],
                        [8.57, -6.88],
                        [8.57, -6.88],
                        [4.32, -0.67],
                        [3.93, -0.21],
                        [3.37, 0],
                        [3.37, 0],
                        [0.5, 0]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.12, -0.08],
                        [-0.09, -0.15],
                        [0, 0],
                        [0, 0],
                        [-0.21, 0.34],
                        [0, 0],
                        [0, 0],
                        [-0.12, 0.1],
                        [-0.18, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.15, 0.14],
                        [0.09, 0.17],
                        [0, 0],
                        [0, 0],
                        [0.17, -0.27],
                        [0, 0],
                        [0, 0],
                        [0.14, -0.13],
                        [0.24, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.27, 0],
                        [0.12, 0.09],
                        [0, 0],
                        [0, 0],
                        [0.11, -0.33],
                        [0, 0],
                        [0, 0],
                        [0.12, -0.17],
                        [0.12, -0.1],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.27, 0],
                        [-0.15, -0.13],
                        [0, 0],
                        [0, 0],
                        [-0.09, 0.35],
                        [0, 0],
                        [0, 0],
                        [-0.12, 0.17],
                        [-0.13, 0.14],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 45,
          ty: 4,
          nm: '',
          ln: 'RRdpY1czGHlO_i3a6LTNS45',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49965.87, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [2.99, -18.23],
                        [6.19, -18.23],
                        [6.19, 0],
                        [2.99, 0],
                        [2.99, -18.23]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [6.91, -23.96],
                        [6.91, -23.96],
                        [6.72, -23.09],
                        [6.22, -22.37],
                        [5.49, -21.87],
                        [4.61, -21.69],
                        [4.61, -21.69],
                        [3.74, -21.87],
                        [3.02, -22.37],
                        [2.52, -23.09],
                        [2.34, -23.96],
                        [2.34, -23.96],
                        [2.52, -24.85],
                        [3.02, -25.59],
                        [3.74, -26.08],
                        [4.61, -26.26],
                        [4.61, -26.26],
                        [5.49, -26.08],
                        [6.22, -25.59],
                        [6.72, -24.85],
                        [6.91, -23.96]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.13, -0.27],
                        [0.21, -0.21],
                        [0.27, -0.12],
                        [0.31, 0],
                        [0, 0],
                        [0.27, 0.12],
                        [0.21, 0.21],
                        [0.12, 0.27],
                        [0, 0.31],
                        [0, 0],
                        [-0.12, 0.28],
                        [-0.21, 0.21],
                        [-0.27, 0.12],
                        [-0.31, 0],
                        [0, 0],
                        [-0.27, -0.12],
                        [-0.21, -0.21],
                        [-0.13, -0.28],
                        [0, -0.31]
                      ],
                      o: [
                        [0, 0],
                        [0, 0.31],
                        [-0.13, 0.27],
                        [-0.21, 0.21],
                        [-0.27, 0.12],
                        [0, 0],
                        [-0.31, 0],
                        [-0.27, -0.12],
                        [-0.21, -0.21],
                        [-0.12, -0.27],
                        [0, 0],
                        [0, -0.31],
                        [0.12, -0.28],
                        [0.21, -0.21],
                        [0.27, -0.12],
                        [0, 0],
                        [0.31, 0],
                        [0.27, 0.12],
                        [0.21, 0.21],
                        [0.13, 0.28],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 46,
          ty: 4,
          nm: '',
          ln: 'Fy1mzOVRlatzjlJLa9WaI46',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49947.01, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [9.85, -18.52],
                        [9.85, -18.52],
                        [12.87, -17.97],
                        [15.26, -16.39],
                        [16.84, -13.83],
                        [17.41, -10.37],
                        [17.41, -10.37],
                        [17.24, -9.36],
                        [16.63, -9.11],
                        [16.63, -9.11],
                        [4.5, -9.11],
                        [4.97, -6.1],
                        [6.16, -3.97],
                        [7.96, -2.69],
                        [10.3, -2.27],
                        [10.3, -2.27],
                        [12.38, -2.55],
                        [13.88, -3.15],
                        [14.93, -3.75],
                        [15.66, -4.03],
                        [15.66, -4.03],
                        [16.27, -3.73],
                        [16.27, -3.73],
                        [17.17, -2.56],
                        [15.75, -1.31],
                        [13.98, -0.43],
                        [12.02, 0.08],
                        [10.03, 0.25],
                        [10.03, 0.25],
                        [6.54, -0.39],
                        [3.79, -2.26],
                        [1.98, -5.31],
                        [1.33, -9.49],
                        [1.33, -9.49],
                        [1.92, -13.05],
                        [3.6, -15.92],
                        [6.28, -17.83],
                        [9.85, -18.52]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.93, -0.37],
                        [-0.67, -0.69],
                        [-0.38, -1.01],
                        [0, -1.29],
                        [0, 0],
                        [0.11, -0.17],
                        [0.3, 0],
                        [0, 0],
                        [0, 0],
                        [-0.29, -0.85],
                        [-0.51, -0.57],
                        [-0.7, -0.28],
                        [-0.87, 0],
                        [0, 0],
                        [-0.59, 0.19],
                        [-0.42, 0.21],
                        [-0.28, 0.19],
                        [-0.21, 0],
                        [0, 0],
                        [-0.14, -0.2],
                        [0, 0],
                        [0, 0],
                        [0.55, -0.35],
                        [0.63, -0.23],
                        [0.67, -0.11],
                        [0.66, 0],
                        [0, 0],
                        [1.07, 0.43],
                        [0.77, 0.82],
                        [0.43, 1.21],
                        [0, 1.57],
                        [0, 0],
                        [-0.39, 1.11],
                        [-0.73, 0.81],
                        [-1.05, 0.46],
                        [-1.32, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.09, 0],
                        [0.93, 0.37],
                        [0.67, 0.69],
                        [0.38, 1.01],
                        [0, 0],
                        [0, 0.51],
                        [-0.11, 0.17],
                        [0, 0],
                        [0, 0],
                        [0.03, 1.15],
                        [0.29, 0.85],
                        [0.5, 0.57],
                        [0.69, 0.28],
                        [0, 0],
                        [0.8, 0],
                        [0.58, -0.19],
                        [0.42, -0.21],
                        [0.28, -0.19],
                        [0, 0],
                        [0.27, 0],
                        [0, 0],
                        [0, 0],
                        [-0.39, 0.48],
                        [-0.55, 0.36],
                        [-0.63, 0.23],
                        [-0.67, 0.11],
                        [0, 0],
                        [-1.26, 0],
                        [-1.06, -0.43],
                        [-0.77, -0.82],
                        [-0.43, -1.21],
                        [0, 0],
                        [0, -1.27],
                        [0.39, -1.11],
                        [0.73, -0.81],
                        [1.06, -0.46],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [9.92, -16.16],
                        [9.92, -16.16],
                        [6.26, -14.82],
                        [4.61, -11.11],
                        [4.61, -11.11],
                        [14.53, -11.11],
                        [14.22, -13.15],
                        [13.32, -14.75],
                        [11.87, -15.8],
                        [9.92, -16.16]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.89, -0.89],
                        [0.21, -1.58],
                        [0, 0],
                        [0, 0],
                        [0.21, 0.62],
                        [0.39, 0.45],
                        [0.57, 0.25],
                        [0.73, 0]
                      ],
                      o: [
                        [0, 0],
                        [-1.55, 0],
                        [-0.89, 0.89],
                        [0, 0],
                        [0, 0],
                        [0, -0.74],
                        [-0.21, -0.62],
                        [-0.39, -0.45],
                        [-0.57, -0.24],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 47,
          ty: 4,
          nm: '',
          ln: 'cdix-uZ2ga2h3hm-rOdol47',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49926.88, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [17.37, 0],
                        [15.46, 0],
                        [14.6, -0.67],
                        [14.6, -0.67],
                        [14.31, -2.88],
                        [11.64, -0.6],
                        [8.19, 0.25],
                        [8.19, 0.25],
                        [5.35, -0.35],
                        [3.17, -2.12],
                        [1.78, -5.04],
                        [1.3, -9.05],
                        [1.3, -9.05],
                        [1.84, -12.81],
                        [3.39, -15.82],
                        [5.87, -17.83],
                        [9.18, -18.56],
                        [9.18, -18.56],
                        [12.04, -17.99],
                        [14.17, -16.4],
                        [14.17, -16.4],
                        [14.17, -26.51],
                        [17.37, -26.51],
                        [17.37, 0]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.12, 0.45],
                        [0, 0],
                        [0, 0],
                        [1, -0.57],
                        [1.29, 0],
                        [0, 0],
                        [0.85, 0.4],
                        [0.6, 0.78],
                        [0.33, 1.17],
                        [0, 1.51],
                        [0, 0],
                        [-0.36, 1.16],
                        [-0.67, 0.85],
                        [-0.97, 0.49],
                        [-1.23, 0],
                        [0, 0],
                        [-0.79, -0.38],
                        [-0.63, -0.68],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [-0.45, 0],
                        [0, 0],
                        [0, 0],
                        [-0.78, 0.95],
                        [-1.01, 0.57],
                        [0, 0],
                        [-1.05, 0],
                        [-0.85, -0.4],
                        [-0.6, -0.78],
                        [-0.32, -1.17],
                        [0, 0],
                        [0, -1.35],
                        [0.36, -1.15],
                        [0.68, -0.85],
                        [0.97, -0.49],
                        [0, 0],
                        [1.11, 0],
                        [0.79, 0.38],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [9.25, -2.34],
                        [9.25, -2.34],
                        [12, -3.06],
                        [14.17, -5.09],
                        [14.17, -5.09],
                        [14.17, -13.91],
                        [12.23, -15.58],
                        [9.9, -16.06],
                        [9.9, -16.06],
                        [5.98, -14.24],
                        [4.61, -9.05],
                        [4.61, -9.05],
                        [4.91, -6],
                        [5.81, -3.92],
                        [7.27, -2.72],
                        [9.25, -2.34]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.79, 0.48],
                        [-0.66, 0.87],
                        [0, 0],
                        [0, 0],
                        [0.7, 0.32],
                        [0.85, 0],
                        [0, 0],
                        [0.91, -1.21],
                        [0, -2.25],
                        [0, 0],
                        [-0.2, -0.85],
                        [-0.39, -0.54],
                        [-0.57, -0.25],
                        [-0.74, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.05, 0],
                        [0.79, -0.48],
                        [0, 0],
                        [0, 0],
                        [-0.59, -0.79],
                        [-0.7, -0.32],
                        [0, 0],
                        [-1.71, 0],
                        [-0.91, 1.21],
                        [0, 0],
                        [0, 1.19],
                        [0.21, 0.85],
                        [0.4, 0.55],
                        [0.58, 0.25],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        }
      ]
    },
    {
      id: 'VGZ7-xc4aIXKkaOA6hkgE',
      layers: [
        {
          ddd: 0,
          ind: 39,
          ty: 0,
          nm: '',
          ln: 'precomp_8nr-KFcHckKI4zQmTyaQl39',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: {
              a: 1,
              k: [
                { t: 0, s: [50000, 50000], h: 1 },
                {
                  t: 0,
                  s: [50000, 50043.2],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 0, s: [50000, 50043.2], h: 1 },
                {
                  t: 52,
                  s: [50000, 50043.2],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 64, s: [50000, 50000], h: 1 }
              ]
            },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'zQXmk1NP95ol9SIaksew1'
        }
      ]
    },
    {
      id: 'QOmJwKj1iq-fTEqqB7mRF',
      layers: [
        {
          ddd: 0,
          ind: 48,
          ty: 4,
          nm: '',
          td: 1,
          ln: 'i21mROraXby4_fLDkZ2mh48',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'rc',
                  hd: false,
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [232.63, 43.2] }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 38,
          ty: 0,
          nm: '',
          tt: 1,
          ln: 'precomp_3njzOj04CM0T4sn2lqJDx38',
          sr: 1,
          ks: {
            a: { a: 0, k: [100000, 100000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [100000, 100000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'VGZ7-xc4aIXKkaOA6hkgE'
        }
      ]
    },
    {
      id: 'uTM7gELkjuZAtkgas-flR',
      layers: [
        {
          ddd: 0,
          ind: 21,
          ty: 0,
          nm: '',
          ln: 'precomp_lXjDADwtH4r7aXDB6t9Qo21',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50076.59, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'jea_x4dQCnhglhL16V71f'
        },
        {
          ddd: 0,
          ind: 37,
          ty: 0,
          nm: '',
          ln: 'precomp_3njzOj04CM0T4sn2lqJDx37',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49894.56, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'QOmJwKj1iq-fTEqqB7mRF'
        }
      ]
    },
    {
      id: 'aYmbzI1my5JAqiHDgSrdU',
      layers: [
        {
          ddd: 0,
          ind: 53,
          ty: 4,
          nm: '',
          ln: 'JGWgcdMI17HAv6UvN-cDj53',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50045.4, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [13.79, -16.4],
                        [13.07, -15.23],
                        [12.4, -14.83],
                        [12.4, -14.83],
                        [11.79, -15.03],
                        [10.95, -15.47],
                        [9.77, -15.92],
                        [8.15, -16.13],
                        [8.15, -16.13],
                        [6.7, -15.92],
                        [5.59, -15.35],
                        [4.89, -14.52],
                        [4.64, -13.48],
                        [4.64, -13.48],
                        [5.05, -12.31],
                        [6.12, -11.5],
                        [7.63, -10.9],
                        [9.37, -10.34],
                        [11.11, -9.68],
                        [12.62, -8.78],
                        [13.69, -7.46],
                        [14.09, -5.58],
                        [14.09, -5.58],
                        [13.64, -3.25],
                        [12.31, -1.4],
                        [10.15, -0.16],
                        [7.2, 0.29],
                        [7.2, 0.29],
                        [3.74, -0.33],
                        [1.12, -1.93],
                        [1.12, -1.93],
                        [1.87, -3.15],
                        [2.21, -3.51],
                        [2.74, -3.64],
                        [2.74, -3.64],
                        [3.42, -3.38],
                        [4.29, -2.83],
                        [5.54, -2.27],
                        [7.36, -2.02],
                        [7.36, -2.02],
                        [9, -2.26],
                        [10.17, -2.92],
                        [10.86, -3.87],
                        [11.09, -5.02],
                        [11.09, -5.02],
                        [10.68, -6.27],
                        [9.61, -7.12],
                        [8.09, -7.73],
                        [6.35, -8.28],
                        [4.6, -8.94],
                        [3.08, -9.86],
                        [2.01, -11.24],
                        [1.6, -13.23],
                        [1.6, -13.23],
                        [2.03, -15.24],
                        [3.29, -16.93],
                        [5.33, -18.09],
                        [8.08, -18.52],
                        [8.08, -18.52],
                        [11.31, -17.96],
                        [13.79, -16.4],
                        [13.79, -16.4]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.3, 0],
                        [0, 0],
                        [0.23, 0.13],
                        [0.33, 0.16],
                        [0.46, 0.14],
                        [0.63, 0],
                        [0, 0],
                        [0.43, -0.14],
                        [0.31, -0.24],
                        [0.16, -0.31],
                        [0, -0.37],
                        [0, 0],
                        [-0.27, -0.31],
                        [-0.45, -0.23],
                        [-0.56, -0.17],
                        [-0.59, -0.2],
                        [-0.57, -0.24],
                        [-0.45, -0.36],
                        [-0.27, -0.52],
                        [0, -0.73],
                        [0, 0],
                        [0.3, -0.71],
                        [0.59, -0.52],
                        [0.85, -0.3],
                        [1.11, 0],
                        [0, 0],
                        [1.03, 0.41],
                        [0.72, 0.65],
                        [0, 0],
                        [0, 0],
                        [-0.13, 0.09],
                        [-0.22, 0],
                        [0, 0],
                        [-0.24, -0.17],
                        [-0.34, -0.2],
                        [-0.49, -0.17],
                        [-0.73, 0],
                        [0, 0],
                        [-0.47, 0.16],
                        [-0.31, 0.28],
                        [-0.15, 0.36],
                        [0, 0.41],
                        [0, 0],
                        [0.27, 0.33],
                        [0.45, 0.23],
                        [0.57, 0.17],
                        [0.59, 0.19],
                        [0.57, 0.25],
                        [0.44, 0.37],
                        [0.27, 0.55],
                        [0, 0.78],
                        [0, 0],
                        [-0.29, 0.65],
                        [-0.55, 0.49],
                        [-0.81, 0.29],
                        [-1.03, 0],
                        [0, 0],
                        [-0.95, -0.37],
                        [-0.7, -0.66],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [-0.15, 0.27],
                        [0, 0],
                        [-0.18, 0],
                        [-0.23, -0.13],
                        [-0.33, -0.16],
                        [-0.45, -0.14],
                        [0, 0],
                        [-0.54, 0],
                        [-0.43, 0.14],
                        [-0.31, 0.24],
                        [-0.17, 0.32],
                        [0, 0],
                        [0, 0.47],
                        [0.27, 0.31],
                        [0.45, 0.23],
                        [0.57, 0.17],
                        [0.59, 0.2],
                        [0.56, 0.24],
                        [0.44, 0.36],
                        [0.27, 0.52],
                        [0, 0],
                        [0, 0.84],
                        [-0.3, 0.71],
                        [-0.59, 0.53],
                        [-0.85, 0.3],
                        [0, 0],
                        [-1.27, 0],
                        [-1.03, -0.41],
                        [0, 0],
                        [0, 0],
                        [0.1, -0.15],
                        [0.13, -0.09],
                        [0, 0],
                        [0.21, 0],
                        [0.24, 0.17],
                        [0.35, 0.21],
                        [0.48, 0.17],
                        [0, 0],
                        [0.63, 0],
                        [0.47, -0.16],
                        [0.31, -0.27],
                        [0.15, -0.36],
                        [0, 0],
                        [0, -0.51],
                        [-0.27, -0.33],
                        [-0.44, -0.23],
                        [-0.57, -0.17],
                        [-0.6, -0.19],
                        [-0.57, -0.25],
                        [-0.45, -0.37],
                        [-0.27, -0.55],
                        [0, 0],
                        [0, -0.69],
                        [0.29, -0.64],
                        [0.55, -0.49],
                        [0.8, -0.29],
                        [0, 0],
                        [1.2, 0],
                        [0.95, 0.38],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 54,
          ty: 4,
          nm: '',
          ln: 'JSVMCl4Ci_vU4Pt25j2TW54',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50026.53, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [9.85, -18.52],
                        [9.85, -18.52],
                        [12.87, -17.97],
                        [15.26, -16.39],
                        [16.84, -13.83],
                        [17.41, -10.37],
                        [17.41, -10.37],
                        [17.24, -9.36],
                        [16.63, -9.11],
                        [16.63, -9.11],
                        [4.5, -9.11],
                        [4.97, -6.1],
                        [6.16, -3.97],
                        [7.96, -2.69],
                        [10.3, -2.27],
                        [10.3, -2.27],
                        [12.38, -2.55],
                        [13.88, -3.15],
                        [14.93, -3.75],
                        [15.66, -4.03],
                        [15.66, -4.03],
                        [16.27, -3.73],
                        [16.27, -3.73],
                        [17.17, -2.56],
                        [15.75, -1.31],
                        [13.98, -0.43],
                        [12.02, 0.08],
                        [10.03, 0.25],
                        [10.03, 0.25],
                        [6.54, -0.39],
                        [3.79, -2.26],
                        [1.98, -5.31],
                        [1.33, -9.49],
                        [1.33, -9.49],
                        [1.92, -13.05],
                        [3.6, -15.92],
                        [6.28, -17.83],
                        [9.85, -18.52]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.93, -0.37],
                        [-0.67, -0.69],
                        [-0.38, -1.01],
                        [0, -1.29],
                        [0, 0],
                        [0.11, -0.17],
                        [0.3, 0],
                        [0, 0],
                        [0, 0],
                        [-0.29, -0.85],
                        [-0.51, -0.57],
                        [-0.7, -0.28],
                        [-0.87, 0],
                        [0, 0],
                        [-0.59, 0.19],
                        [-0.42, 0.21],
                        [-0.28, 0.19],
                        [-0.21, 0],
                        [0, 0],
                        [-0.14, -0.2],
                        [0, 0],
                        [0, 0],
                        [0.55, -0.35],
                        [0.63, -0.23],
                        [0.67, -0.11],
                        [0.66, 0],
                        [0, 0],
                        [1.07, 0.43],
                        [0.77, 0.82],
                        [0.43, 1.21],
                        [0, 1.57],
                        [0, 0],
                        [-0.39, 1.11],
                        [-0.73, 0.81],
                        [-1.05, 0.46],
                        [-1.32, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.09, 0],
                        [0.93, 0.37],
                        [0.67, 0.69],
                        [0.38, 1.01],
                        [0, 0],
                        [0, 0.51],
                        [-0.11, 0.17],
                        [0, 0],
                        [0, 0],
                        [0.03, 1.15],
                        [0.29, 0.85],
                        [0.5, 0.57],
                        [0.69, 0.28],
                        [0, 0],
                        [0.8, 0],
                        [0.58, -0.19],
                        [0.42, -0.21],
                        [0.28, -0.19],
                        [0, 0],
                        [0.27, 0],
                        [0, 0],
                        [0, 0],
                        [-0.39, 0.48],
                        [-0.55, 0.36],
                        [-0.63, 0.23],
                        [-0.67, 0.11],
                        [0, 0],
                        [-1.26, 0],
                        [-1.06, -0.43],
                        [-0.77, -0.82],
                        [-0.43, -1.21],
                        [0, 0],
                        [0, -1.27],
                        [0.39, -1.11],
                        [0.73, -0.81],
                        [1.06, -0.46],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [9.92, -16.16],
                        [9.92, -16.16],
                        [6.26, -14.82],
                        [4.61, -11.11],
                        [4.61, -11.11],
                        [14.53, -11.11],
                        [14.22, -13.15],
                        [13.32, -14.75],
                        [11.87, -15.8],
                        [9.92, -16.16]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.89, -0.89],
                        [0.21, -1.58],
                        [0, 0],
                        [0, 0],
                        [0.21, 0.62],
                        [0.39, 0.45],
                        [0.57, 0.25],
                        [0.73, 0]
                      ],
                      o: [
                        [0, 0],
                        [-1.55, 0],
                        [-0.89, 0.89],
                        [0, 0],
                        [0, 0],
                        [0, -0.74],
                        [-0.21, -0.62],
                        [-0.39, -0.45],
                        [-0.57, -0.24],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 55,
          ty: 4,
          nm: '',
          ln: 'qZQSpL6sOacoyUp6zVH-m55',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50013.1, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [8.15, 0.29],
                        [8.15, 0.29],
                        [4.83, -0.92],
                        [3.67, -4.39],
                        [3.67, -4.39],
                        [3.67, -15.55],
                        [1.48, -15.55],
                        [0.99, -15.72],
                        [0.79, -16.25],
                        [0.79, -16.25],
                        [0.79, -17.53],
                        [3.78, -17.91],
                        [4.52, -23.54],
                        [4.75, -23.99],
                        [5.26, -24.16],
                        [5.26, -24.16],
                        [6.88, -24.16],
                        [6.88, -17.87],
                        [12.1, -17.87],
                        [12.1, -15.55],
                        [6.88, -15.55],
                        [6.88, -4.61],
                        [7.43, -2.9],
                        [8.87, -2.34],
                        [8.87, -2.34],
                        [9.75, -2.48],
                        [10.39, -2.77],
                        [10.85, -3.07],
                        [11.18, -3.2],
                        [11.18, -3.2],
                        [11.63, -2.9],
                        [11.63, -2.9],
                        [12.56, -1.37],
                        [10.57, -0.15],
                        [8.15, 0.29]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.77, 0.81],
                        [0, 1.51],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.13, 0.11],
                        [0, 0.24],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.13, 0.12],
                        [-0.21, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.37, -0.37],
                        [-0.59, 0],
                        [0, 0],
                        [-0.25, 0.09],
                        [-0.18, 0.11],
                        [-0.13, 0.09],
                        [-0.1, 0],
                        [0, 0],
                        [-0.13, -0.2],
                        [0, 0],
                        [0, 0],
                        [0.78, -0.29],
                        [0.83, 0]
                      ],
                      o: [
                        [0, 0],
                        [-1.44, 0],
                        [-0.77, -0.8],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.19, 0],
                        [-0.13, -0.11],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.02, -0.18],
                        [0.13, -0.11],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.77],
                        [0.37, 0.37],
                        [0, 0],
                        [0.34, 0],
                        [0.25, -0.09],
                        [0.18, -0.11],
                        [0.12, -0.09],
                        [0, 0],
                        [0.17, 0],
                        [0, 0],
                        [0, 0],
                        [-0.55, 0.52],
                        [-0.78, 0.29],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 56,
          ty: 4,
          nm: '',
          ln: 'l-ylHSJuDPr6zK9POrJVy56',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49993.09, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [5.83, 0],
                        [2.63, 0],
                        [2.63, -18.23],
                        [4.54, -18.23],
                        [5.4, -17.57],
                        [5.4, -17.57],
                        [5.65, -15.59],
                        [8.31, -17.71],
                        [11.7, -18.52],
                        [11.7, -18.52],
                        [14.34, -18.03],
                        [16.25, -16.62],
                        [17.41, -14.44],
                        [17.8, -11.61],
                        [17.8, -11.61],
                        [17.8, 0],
                        [14.6, 0],
                        [14.6, -11.61],
                        [13.65, -14.82],
                        [10.76, -15.97],
                        [10.76, -15.97],
                        [8.11, -15.28],
                        [5.83, -13.43],
                        [5.83, -13.43],
                        [5.83, 0]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.12, -0.44],
                        [0, 0],
                        [0, 0],
                        [-0.98, 0.54],
                        [-1.29, 0],
                        [0, 0],
                        [-0.77, -0.33],
                        [-0.51, -0.61],
                        [-0.27, -0.85],
                        [0, -1.03],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.63, 0.76],
                        [1.3, 0],
                        [0, 0],
                        [0.82, -0.46],
                        [0.7, -0.78],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.45, 0],
                        [0, 0],
                        [0, 0],
                        [0.79, -0.87],
                        [0.97, -0.54],
                        [0, 0],
                        [0.99, 0],
                        [0.76, 0.33],
                        [0.51, 0.61],
                        [0.26, 0.85],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.38],
                        [-0.63, -0.77],
                        [0, 0],
                        [-0.95, 0],
                        [-0.82, 0.45],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 57,
          ty: 4,
          nm: '',
          ln: 'oSwEBqAsEOGwanfoCymiJ57',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49974.22, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [9.85, -18.52],
                        [9.85, -18.52],
                        [12.87, -17.97],
                        [15.26, -16.39],
                        [16.84, -13.83],
                        [17.41, -10.37],
                        [17.41, -10.37],
                        [17.24, -9.36],
                        [16.63, -9.11],
                        [16.63, -9.11],
                        [4.5, -9.11],
                        [4.97, -6.1],
                        [6.16, -3.97],
                        [7.96, -2.69],
                        [10.3, -2.27],
                        [10.3, -2.27],
                        [12.38, -2.55],
                        [13.88, -3.15],
                        [14.93, -3.75],
                        [15.66, -4.03],
                        [15.66, -4.03],
                        [16.27, -3.73],
                        [16.27, -3.73],
                        [17.17, -2.56],
                        [15.75, -1.31],
                        [13.98, -0.43],
                        [12.02, 0.08],
                        [10.03, 0.25],
                        [10.03, 0.25],
                        [6.54, -0.39],
                        [3.79, -2.26],
                        [1.98, -5.31],
                        [1.33, -9.49],
                        [1.33, -9.49],
                        [1.92, -13.05],
                        [3.6, -15.92],
                        [6.28, -17.83],
                        [9.85, -18.52]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.93, -0.37],
                        [-0.67, -0.69],
                        [-0.38, -1.01],
                        [0, -1.29],
                        [0, 0],
                        [0.11, -0.17],
                        [0.3, 0],
                        [0, 0],
                        [0, 0],
                        [-0.29, -0.85],
                        [-0.51, -0.57],
                        [-0.7, -0.28],
                        [-0.87, 0],
                        [0, 0],
                        [-0.59, 0.19],
                        [-0.42, 0.21],
                        [-0.28, 0.19],
                        [-0.21, 0],
                        [0, 0],
                        [-0.14, -0.2],
                        [0, 0],
                        [0, 0],
                        [0.55, -0.35],
                        [0.63, -0.23],
                        [0.67, -0.11],
                        [0.66, 0],
                        [0, 0],
                        [1.07, 0.43],
                        [0.77, 0.82],
                        [0.43, 1.21],
                        [0, 1.57],
                        [0, 0],
                        [-0.39, 1.11],
                        [-0.73, 0.81],
                        [-1.05, 0.46],
                        [-1.32, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.09, 0],
                        [0.93, 0.37],
                        [0.67, 0.69],
                        [0.38, 1.01],
                        [0, 0],
                        [0, 0.51],
                        [-0.11, 0.17],
                        [0, 0],
                        [0, 0],
                        [0.03, 1.15],
                        [0.29, 0.85],
                        [0.5, 0.57],
                        [0.69, 0.28],
                        [0, 0],
                        [0.8, 0],
                        [0.58, -0.19],
                        [0.42, -0.21],
                        [0.28, -0.19],
                        [0, 0],
                        [0.27, 0],
                        [0, 0],
                        [0, 0],
                        [-0.39, 0.48],
                        [-0.55, 0.36],
                        [-0.63, 0.23],
                        [-0.67, 0.11],
                        [0, 0],
                        [-1.26, 0],
                        [-1.06, -0.43],
                        [-0.77, -0.82],
                        [-0.43, -1.21],
                        [0, 0],
                        [0, -1.27],
                        [0.39, -1.11],
                        [0.73, -0.81],
                        [1.06, -0.46],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [9.92, -16.16],
                        [9.92, -16.16],
                        [6.26, -14.82],
                        [4.61, -11.11],
                        [4.61, -11.11],
                        [14.53, -11.11],
                        [14.22, -13.15],
                        [13.32, -14.75],
                        [11.87, -15.8],
                        [9.92, -16.16]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.89, -0.89],
                        [0.21, -1.58],
                        [0, 0],
                        [0, 0],
                        [0.21, 0.62],
                        [0.39, 0.45],
                        [0.57, 0.25],
                        [0.73, 0]
                      ],
                      o: [
                        [0, 0],
                        [-1.55, 0],
                        [-0.89, 0.89],
                        [0, 0],
                        [0, 0],
                        [0, -0.74],
                        [-0.21, -0.62],
                        [-0.39, -0.45],
                        [-0.57, -0.24],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 58,
          ty: 4,
          nm: '',
          ln: '93gFatDh8IRx-b3Rg_zIi58',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49965.01, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [2.99, -18.23],
                        [6.19, -18.23],
                        [6.19, 0],
                        [2.99, 0],
                        [2.99, -18.23]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [6.91, -23.96],
                        [6.91, -23.96],
                        [6.72, -23.09],
                        [6.22, -22.37],
                        [5.49, -21.87],
                        [4.61, -21.69],
                        [4.61, -21.69],
                        [3.74, -21.87],
                        [3.02, -22.37],
                        [2.52, -23.09],
                        [2.34, -23.96],
                        [2.34, -23.96],
                        [2.52, -24.85],
                        [3.02, -25.59],
                        [3.74, -26.08],
                        [4.61, -26.26],
                        [4.61, -26.26],
                        [5.49, -26.08],
                        [6.22, -25.59],
                        [6.72, -24.85],
                        [6.91, -23.96]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.13, -0.27],
                        [0.21, -0.21],
                        [0.27, -0.12],
                        [0.31, 0],
                        [0, 0],
                        [0.27, 0.12],
                        [0.21, 0.21],
                        [0.12, 0.27],
                        [0, 0.31],
                        [0, 0],
                        [-0.12, 0.28],
                        [-0.21, 0.21],
                        [-0.27, 0.12],
                        [-0.31, 0],
                        [0, 0],
                        [-0.27, -0.12],
                        [-0.21, -0.21],
                        [-0.13, -0.28],
                        [0, -0.31]
                      ],
                      o: [
                        [0, 0],
                        [0, 0.31],
                        [-0.13, 0.27],
                        [-0.21, 0.21],
                        [-0.27, 0.12],
                        [0, 0],
                        [-0.31, 0],
                        [-0.27, -0.12],
                        [-0.21, -0.21],
                        [-0.12, -0.27],
                        [0, 0],
                        [0, -0.31],
                        [0.12, -0.28],
                        [0.21, -0.21],
                        [0.27, -0.12],
                        [0, 0],
                        [0.31, 0],
                        [0.27, 0.12],
                        [0.21, 0.21],
                        [0.13, 0.28],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 59,
          ty: 4,
          nm: '',
          ln: 'CSpw20zkagSaIYpR77ROr59',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49955.79, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [2.99, -26.51],
                        [6.19, -26.51],
                        [6.19, 0],
                        [2.99, 0],
                        [2.99, -26.51]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 60,
          ty: 4,
          nm: '',
          ln: 'q-u6SaaleEey_k548Mn4o60',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49938.98, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [15.91, -16.15],
                        [15.07, -14.99],
                        [14.78, -14.69],
                        [14.36, -14.58],
                        [14.36, -14.58],
                        [13.78, -14.81],
                        [12.98, -15.3],
                        [11.8, -15.8],
                        [10.1, -16.02],
                        [10.1, -16.02],
                        [7.74, -15.54],
                        [6.02, -14.17],
                        [4.98, -11.99],
                        [4.63, -9.13],
                        [4.63, -9.13],
                        [5, -6.18],
                        [6.07, -4.02],
                        [7.73, -2.69],
                        [9.94, -2.23],
                        [9.94, -2.23],
                        [11.86, -2.51],
                        [13.12, -3.13],
                        [13.95, -3.75],
                        [14.6, -4.03],
                        [14.6, -4.03],
                        [15.21, -3.73],
                        [15.21, -3.73],
                        [16.11, -2.56],
                        [13.14, -0.42],
                        [9.38, 0.25],
                        [9.38, 0.25],
                        [6.2, -0.38],
                        [3.65, -2.21],
                        [1.95, -5.15],
                        [1.33, -9.13],
                        [1.33, -9.13],
                        [1.9, -12.89],
                        [3.56, -15.87],
                        [6.25, -17.82],
                        [9.92, -18.52],
                        [9.92, -18.52],
                        [13.3, -17.9],
                        [15.91, -16.15],
                        [15.91, -16.15]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.09, -0.07],
                        [0.18, 0],
                        [0, 0],
                        [0.21, 0.15],
                        [0.32, 0.18],
                        [0.46, 0.15],
                        [0.67, 0],
                        [0, 0],
                        [0.69, -0.32],
                        [0.46, -0.6],
                        [0.23, -0.85],
                        [0, -1.05],
                        [0, 0],
                        [-0.25, -0.86],
                        [-0.46, -0.59],
                        [-0.65, -0.31],
                        [-0.82, 0],
                        [0, 0],
                        [-0.5, 0.19],
                        [-0.33, 0.23],
                        [-0.21, 0.19],
                        [-0.22, 0],
                        [0, 0],
                        [-0.13, -0.2],
                        [0, 0],
                        [0, 0],
                        [1.19, -0.45],
                        [1.32, 0],
                        [0, 0],
                        [0.98, 0.42],
                        [0.72, 0.8],
                        [0.41, 1.17],
                        [0, 1.49],
                        [0, 0],
                        [-0.38, 1.15],
                        [-0.73, 0.83],
                        [-1.07, 0.47],
                        [-1.38, 0],
                        [0, 0],
                        [-0.98, -0.41],
                        [-0.75, -0.75],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [-0.1, 0.13],
                        [-0.1, 0.07],
                        [0, 0],
                        [-0.18, 0],
                        [-0.21, -0.15],
                        [-0.33, -0.18],
                        [-0.46, -0.15],
                        [0, 0],
                        [-0.89, 0],
                        [-0.69, 0.31],
                        [-0.46, 0.6],
                        [-0.23, 0.85],
                        [0, 0],
                        [0, 1.11],
                        [0.25, 0.85],
                        [0.45, 0.58],
                        [0.65, 0.31],
                        [0, 0],
                        [0.78, 0],
                        [0.51, -0.19],
                        [0.34, -0.23],
                        [0.21, -0.19],
                        [0, 0],
                        [0.27, 0],
                        [0, 0],
                        [0, 0],
                        [-0.79, 0.97],
                        [-1.19, 0.45],
                        [0, 0],
                        [-1.14, 0],
                        [-0.98, -0.42],
                        [-0.72, -0.79],
                        [-0.41, -1.16],
                        [0, 0],
                        [0, -1.35],
                        [0.38, -1.15],
                        [0.72, -0.83],
                        [1.07, -0.47],
                        [0, 0],
                        [1.27, 0],
                        [0.99, 0.41],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        }
      ]
    },
    {
      id: '5JnTje47rf-7iw-mHm1gE',
      layers: [
        {
          ddd: 0,
          ind: 52,
          ty: 0,
          nm: '',
          ln: 'precomp_-svULFTnEVu6JZPVx-Dfp52',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: {
              a: 1,
              k: [
                { t: 0, s: [50000, 50000], h: 1 },
                {
                  t: 0,
                  s: [50000, 50043.2],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 0, s: [50000, 50043.2], h: 1 },
                {
                  t: 50,
                  s: [50000, 50043.2],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 58, s: [50000, 50000], h: 1 }
              ]
            },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'aYmbzI1my5JAqiHDgSrdU'
        }
      ]
    },
    {
      id: 'xUzAeASnigKF-8SN4K9Xc',
      layers: [
        {
          ddd: 0,
          ind: 61,
          ty: 4,
          nm: '',
          td: 1,
          ln: 'FzpimPzJIGRzk3b2TQXHZ61',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'rc',
                  hd: false,
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [208.44, 43.2] }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 51,
          ty: 0,
          nm: '',
          tt: 1,
          ln: 'precomp_8n1J_Ebjly_nqsc78XC1451',
          sr: 1,
          ks: {
            a: { a: 0, k: [100000, 100000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [100000, 100000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: '5JnTje47rf-7iw-mHm1gE'
        }
      ]
    },
    {
      id: 'FpDK5HNsphVcS7Z1H05Ht',
      layers: [
        {
          ddd: 0,
          ind: 65,
          ty: 4,
          nm: '',
          ln: '2WZWKAjN8pB9b3m2Ocs1j65',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50021.17, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [13.79, -16.4],
                        [13.07, -15.23],
                        [12.4, -14.83],
                        [12.4, -14.83],
                        [11.79, -15.03],
                        [10.95, -15.47],
                        [9.77, -15.92],
                        [8.15, -16.13],
                        [8.15, -16.13],
                        [6.7, -15.92],
                        [5.59, -15.35],
                        [4.89, -14.52],
                        [4.64, -13.48],
                        [4.64, -13.48],
                        [5.05, -12.31],
                        [6.12, -11.5],
                        [7.63, -10.9],
                        [9.37, -10.34],
                        [11.11, -9.68],
                        [12.62, -8.78],
                        [13.69, -7.46],
                        [14.09, -5.58],
                        [14.09, -5.58],
                        [13.64, -3.25],
                        [12.31, -1.4],
                        [10.15, -0.16],
                        [7.2, 0.29],
                        [7.2, 0.29],
                        [3.74, -0.33],
                        [1.12, -1.93],
                        [1.12, -1.93],
                        [1.87, -3.15],
                        [2.21, -3.51],
                        [2.74, -3.64],
                        [2.74, -3.64],
                        [3.42, -3.38],
                        [4.29, -2.83],
                        [5.54, -2.27],
                        [7.36, -2.02],
                        [7.36, -2.02],
                        [9, -2.26],
                        [10.17, -2.92],
                        [10.86, -3.87],
                        [11.09, -5.02],
                        [11.09, -5.02],
                        [10.68, -6.27],
                        [9.61, -7.12],
                        [8.09, -7.73],
                        [6.35, -8.28],
                        [4.6, -8.94],
                        [3.08, -9.86],
                        [2.01, -11.24],
                        [1.6, -13.23],
                        [1.6, -13.23],
                        [2.03, -15.24],
                        [3.29, -16.93],
                        [5.33, -18.09],
                        [8.08, -18.52],
                        [8.08, -18.52],
                        [11.31, -17.96],
                        [13.79, -16.4],
                        [13.79, -16.4]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.3, 0],
                        [0, 0],
                        [0.23, 0.13],
                        [0.33, 0.16],
                        [0.46, 0.14],
                        [0.63, 0],
                        [0, 0],
                        [0.43, -0.14],
                        [0.31, -0.24],
                        [0.16, -0.31],
                        [0, -0.37],
                        [0, 0],
                        [-0.27, -0.31],
                        [-0.45, -0.23],
                        [-0.56, -0.17],
                        [-0.59, -0.2],
                        [-0.57, -0.24],
                        [-0.45, -0.36],
                        [-0.27, -0.52],
                        [0, -0.73],
                        [0, 0],
                        [0.3, -0.71],
                        [0.59, -0.52],
                        [0.85, -0.3],
                        [1.11, 0],
                        [0, 0],
                        [1.03, 0.41],
                        [0.72, 0.65],
                        [0, 0],
                        [0, 0],
                        [-0.13, 0.09],
                        [-0.22, 0],
                        [0, 0],
                        [-0.24, -0.17],
                        [-0.34, -0.2],
                        [-0.49, -0.17],
                        [-0.73, 0],
                        [0, 0],
                        [-0.47, 0.16],
                        [-0.31, 0.28],
                        [-0.15, 0.36],
                        [0, 0.41],
                        [0, 0],
                        [0.27, 0.33],
                        [0.45, 0.23],
                        [0.57, 0.17],
                        [0.59, 0.19],
                        [0.57, 0.25],
                        [0.44, 0.37],
                        [0.27, 0.55],
                        [0, 0.78],
                        [0, 0],
                        [-0.29, 0.65],
                        [-0.55, 0.49],
                        [-0.81, 0.29],
                        [-1.03, 0],
                        [0, 0],
                        [-0.95, -0.37],
                        [-0.7, -0.66],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [-0.15, 0.27],
                        [0, 0],
                        [-0.18, 0],
                        [-0.23, -0.13],
                        [-0.33, -0.16],
                        [-0.45, -0.14],
                        [0, 0],
                        [-0.54, 0],
                        [-0.43, 0.14],
                        [-0.31, 0.24],
                        [-0.17, 0.32],
                        [0, 0],
                        [0, 0.47],
                        [0.27, 0.31],
                        [0.45, 0.23],
                        [0.57, 0.17],
                        [0.59, 0.2],
                        [0.56, 0.24],
                        [0.44, 0.36],
                        [0.27, 0.52],
                        [0, 0],
                        [0, 0.84],
                        [-0.3, 0.71],
                        [-0.59, 0.53],
                        [-0.85, 0.3],
                        [0, 0],
                        [-1.27, 0],
                        [-1.03, -0.41],
                        [0, 0],
                        [0, 0],
                        [0.1, -0.15],
                        [0.13, -0.09],
                        [0, 0],
                        [0.21, 0],
                        [0.24, 0.17],
                        [0.35, 0.21],
                        [0.48, 0.17],
                        [0, 0],
                        [0.63, 0],
                        [0.47, -0.16],
                        [0.31, -0.27],
                        [0.15, -0.36],
                        [0, 0],
                        [0, -0.51],
                        [-0.27, -0.33],
                        [-0.44, -0.23],
                        [-0.57, -0.17],
                        [-0.6, -0.19],
                        [-0.57, -0.25],
                        [-0.45, -0.37],
                        [-0.27, -0.55],
                        [0, 0],
                        [0, -0.69],
                        [0.29, -0.64],
                        [0.55, -0.49],
                        [0.8, -0.29],
                        [0, 0],
                        [1.2, 0],
                        [0.95, 0.38],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 66,
          ty: 4,
          nm: '',
          ln: 'FHi4y1NFMQx93HBaWVieH66',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50001.15, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [2.2, -18.23],
                        [5.4, -18.23],
                        [5.4, -6.61],
                        [6.35, -3.4],
                        [9.23, -2.27],
                        [9.23, -2.27],
                        [11.88, -2.93],
                        [14.17, -4.79],
                        [14.17, -4.79],
                        [14.17, -18.23],
                        [17.37, -18.23],
                        [17.37, 0],
                        [15.46, 0],
                        [14.6, -0.67],
                        [14.6, -0.67],
                        [14.35, -2.63],
                        [11.68, -0.51],
                        [8.3, 0.29],
                        [8.3, 0.29],
                        [5.66, -0.21],
                        [3.74, -1.6],
                        [2.58, -3.78],
                        [2.2, -6.61],
                        [2.2, -6.61],
                        [2.2, -18.23]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.63, -0.76],
                        [-1.28, 0],
                        [0, 0],
                        [-0.83, 0.44],
                        [-0.7, 0.79],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.12, 0.45],
                        [0, 0],
                        [0, 0],
                        [0.99, -0.53],
                        [1.27, 0],
                        [0, 0],
                        [0.76, 0.33],
                        [0.52, 0.6],
                        [0.26, 0.85],
                        [0, 1.03],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 1.38],
                        [0.64, 0.75],
                        [0, 0],
                        [0.94, 0],
                        [0.83, -0.45],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.45, 0],
                        [0, 0],
                        [0, 0],
                        [-0.79, 0.88],
                        [-0.98, 0.53],
                        [0, 0],
                        [-1, 0],
                        [-0.76, -0.33],
                        [-0.51, -0.6],
                        [-0.25, -0.85],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 67,
          ty: 4,
          nm: '',
          ln: 'uxe3tRwvlyQHUrfy_Un7F67',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49982.29, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [9.85, -18.52],
                        [9.85, -18.52],
                        [12.87, -17.97],
                        [15.26, -16.39],
                        [16.84, -13.83],
                        [17.41, -10.37],
                        [17.41, -10.37],
                        [17.24, -9.36],
                        [16.63, -9.11],
                        [16.63, -9.11],
                        [4.5, -9.11],
                        [4.97, -6.1],
                        [6.16, -3.97],
                        [7.96, -2.69],
                        [10.3, -2.27],
                        [10.3, -2.27],
                        [12.38, -2.55],
                        [13.88, -3.15],
                        [14.93, -3.75],
                        [15.66, -4.03],
                        [15.66, -4.03],
                        [16.27, -3.73],
                        [16.27, -3.73],
                        [17.17, -2.56],
                        [15.75, -1.31],
                        [13.98, -0.43],
                        [12.02, 0.08],
                        [10.03, 0.25],
                        [10.03, 0.25],
                        [6.54, -0.39],
                        [3.79, -2.26],
                        [1.98, -5.31],
                        [1.33, -9.49],
                        [1.33, -9.49],
                        [1.92, -13.05],
                        [3.6, -15.92],
                        [6.28, -17.83],
                        [9.85, -18.52]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.93, -0.37],
                        [-0.67, -0.69],
                        [-0.38, -1.01],
                        [0, -1.29],
                        [0, 0],
                        [0.11, -0.17],
                        [0.3, 0],
                        [0, 0],
                        [0, 0],
                        [-0.29, -0.85],
                        [-0.51, -0.57],
                        [-0.7, -0.28],
                        [-0.87, 0],
                        [0, 0],
                        [-0.59, 0.19],
                        [-0.42, 0.21],
                        [-0.28, 0.19],
                        [-0.21, 0],
                        [0, 0],
                        [-0.14, -0.2],
                        [0, 0],
                        [0, 0],
                        [0.55, -0.35],
                        [0.63, -0.23],
                        [0.67, -0.11],
                        [0.66, 0],
                        [0, 0],
                        [1.07, 0.43],
                        [0.77, 0.82],
                        [0.43, 1.21],
                        [0, 1.57],
                        [0, 0],
                        [-0.39, 1.11],
                        [-0.73, 0.81],
                        [-1.05, 0.46],
                        [-1.32, 0]
                      ],
                      o: [
                        [0, 0],
                        [1.09, 0],
                        [0.93, 0.37],
                        [0.67, 0.69],
                        [0.38, 1.01],
                        [0, 0],
                        [0, 0.51],
                        [-0.11, 0.17],
                        [0, 0],
                        [0, 0],
                        [0.03, 1.15],
                        [0.29, 0.85],
                        [0.5, 0.57],
                        [0.69, 0.28],
                        [0, 0],
                        [0.8, 0],
                        [0.58, -0.19],
                        [0.42, -0.21],
                        [0.28, -0.19],
                        [0, 0],
                        [0.27, 0],
                        [0, 0],
                        [0, 0],
                        [-0.39, 0.48],
                        [-0.55, 0.36],
                        [-0.63, 0.23],
                        [-0.67, 0.11],
                        [0, 0],
                        [-1.26, 0],
                        [-1.06, -0.43],
                        [-0.77, -0.82],
                        [-0.43, -1.21],
                        [0, 0],
                        [0, -1.27],
                        [0.39, -1.11],
                        [0.73, -0.81],
                        [1.06, -0.46],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'sh',
                  hd: false,
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [9.92, -16.16],
                        [9.92, -16.16],
                        [6.26, -14.82],
                        [4.61, -11.11],
                        [4.61, -11.11],
                        [14.53, -11.11],
                        [14.22, -13.15],
                        [13.32, -14.75],
                        [11.87, -15.8],
                        [9.92, -16.16]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.89, -0.89],
                        [0.21, -1.58],
                        [0, 0],
                        [0, 0],
                        [0.21, 0.62],
                        [0.39, 0.45],
                        [0.57, 0.25],
                        [0.73, 0]
                      ],
                      o: [
                        [0, 0],
                        [-1.55, 0],
                        [-0.89, 0.89],
                        [0, 0],
                        [0, 0],
                        [0, -0.74],
                        [-0.21, -0.62],
                        [-0.39, -0.45],
                        [-0.57, -0.24],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 68,
          ty: 4,
          nm: '',
          ln: '9rdtk7iqDjXUjropxniaj68',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49963.21, 50013.93] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'sh',
                  hd: false,
                  ix: 0,
                  ks: {
                    a: 0,
                    k: {
                      v: [
                        [17.19, -23.42],
                        [16.34, -21.76],
                        [16, -21.36],
                        [15.53, -21.22],
                        [15.53, -21.22],
                        [14.82, -21.53],
                        [13.8, -22.2],
                        [12.3, -22.88],
                        [10.19, -23.18],
                        [10.19, -23.18],
                        [8.12, -22.87],
                        [6.62, -22.01],
                        [5.71, -20.75],
                        [5.4, -19.17],
                        [5.4, -19.17],
                        [5.93, -17.38],
                        [7.34, -16.16],
                        [9.32, -15.29],
                        [11.58, -14.53],
                        [13.85, -13.64],
                        [15.83, -12.38],
                        [17.24, -10.53],
                        [17.77, -7.83],
                        [17.77, -7.83],
                        [17.19, -4.65],
                        [15.51, -2.07],
                        [12.79, -0.34],
                        [9.13, 0.29],
                        [9.13, 0.29],
                        [4.56, -0.62],
                        [1.04, -3.08],
                        [1.04, -3.08],
                        [2.05, -4.73],
                        [2.4, -5.07],
                        [2.86, -5.2],
                        [2.86, -5.2],
                        [3.73, -4.8],
                        [4.94, -3.91],
                        [6.71, -3.02],
                        [9.23, -2.61],
                        [9.23, -2.61],
                        [11.45, -2.95],
                        [13.1, -3.92],
                        [14.13, -5.4],
                        [14.49, -7.33],
                        [14.49, -7.33],
                        [13.96, -9.24],
                        [12.56, -10.49],
                        [10.59, -11.35],
                        [8.33, -12.07],
                        [6.06, -12.92],
                        [4.09, -14.18],
                        [2.69, -16.12],
                        [2.16, -18.99],
                        [2.16, -18.99],
                        [2.69, -21.64],
                        [4.23, -23.9],
                        [6.72, -25.49],
                        [10.13, -26.08],
                        [10.13, -26.08],
                        [14.08, -25.4],
                        [17.19, -23.42],
                        [17.19, -23.42]
                      ],
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.12, -0.09],
                        [0.19, 0],
                        [0, 0],
                        [0.27, 0.21],
                        [0.41, 0.25],
                        [0.59, 0.21],
                        [0.83, 0],
                        [0, 0],
                        [0.6, -0.21],
                        [0.4, -0.36],
                        [0.2, -0.48],
                        [0, -0.57],
                        [0, 0],
                        [-0.35, -0.47],
                        [-0.59, -0.34],
                        [-0.74, -0.25],
                        [-0.77, -0.26],
                        [-0.74, -0.33],
                        [-0.58, -0.51],
                        [-0.36, -0.73],
                        [0, -1.07],
                        [0, 0],
                        [0.39, -0.99],
                        [0.73, -0.73],
                        [1.07, -0.42],
                        [1.37, 0],
                        [0, 0],
                        [1.37, 0.61],
                        [0.97, 1.03],
                        [0, 0],
                        [0, 0],
                        [-0.13, 0.09],
                        [-0.17, 0],
                        [0, 0],
                        [-0.33, -0.27],
                        [-0.49, -0.32],
                        [-0.69, -0.27],
                        [-0.99, 0],
                        [0, 0],
                        [-0.65, 0.23],
                        [-0.45, 0.42],
                        [-0.24, 0.57],
                        [0, 0.71],
                        [0, 0],
                        [0.35, 0.49],
                        [0.58, 0.33],
                        [0.74, 0.23],
                        [0.77, 0.25],
                        [0.73, 0.32],
                        [0.57, 0.51],
                        [0.35, 0.77],
                        [0, 1.14],
                        [0, 0],
                        [-0.35, 0.85],
                        [-0.67, 0.66],
                        [-0.99, 0.4],
                        [-1.28, 0],
                        [0, 0],
                        [-1.19, -0.45],
                        [-0.89, -0.86],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [-0.11, 0.18],
                        [-0.12, 0.09],
                        [0, 0],
                        [-0.2, 0],
                        [-0.27, -0.2],
                        [-0.41, -0.25],
                        [-0.58, -0.2],
                        [0, 0],
                        [-0.78, 0],
                        [-0.6, 0.21],
                        [-0.41, 0.36],
                        [-0.21, 0.49],
                        [0, 0],
                        [0, 0.72],
                        [0.35, 0.47],
                        [0.58, 0.33],
                        [0.73, 0.25],
                        [0.77, 0.27],
                        [0.74, 0.33],
                        [0.58, 0.5],
                        [0.35, 0.73],
                        [0, 0],
                        [0, 1.13],
                        [-0.39, 0.99],
                        [-0.74, 0.73],
                        [-1.07, 0.42],
                        [0, 0],
                        [-1.67, 0],
                        [-1.37, -0.61],
                        [0, 0],
                        [0, 0],
                        [0.1, -0.13],
                        [0.14, -0.09],
                        [0, 0],
                        [0.25, 0],
                        [0.32, 0.27],
                        [0.49, 0.33],
                        [0.69, 0.27],
                        [0, 0],
                        [0.83, 0],
                        [0.65, -0.23],
                        [0.45, -0.41],
                        [0.24, -0.57],
                        [0, 0],
                        [0, -0.78],
                        [-0.35, -0.5],
                        [-0.57, -0.34],
                        [-0.73, -0.23],
                        [-0.78, -0.25],
                        [-0.74, -0.33],
                        [-0.58, -0.52],
                        [-0.35, -0.77],
                        [0, 0],
                        [0, -0.91],
                        [0.35, -0.85],
                        [0.67, -0.66],
                        [0.99, -0.39],
                        [0, 0],
                        [1.44, 0],
                        [1.19, 0.46],
                        [0, 0],
                        [0, 0]
                      ]
                    }
                  }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        }
      ]
    },
    {
      id: 'cZHQvT-GYNdE9GukcmrcW',
      layers: [
        {
          ddd: 0,
          ind: 64,
          ty: 0,
          nm: '',
          ln: 'precomp_N8a_APtAlD0xd-q3W6IKw64',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: {
              a: 1,
              k: [
                { t: 0, s: [50000, 50000], h: 1 },
                {
                  t: 0,
                  s: [50000, 50043.2],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 0, s: [50000, 50043.2], h: 1 },
                {
                  t: 50,
                  s: [50000, 50043.2],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 55, s: [50000, 50000], h: 1 }
              ]
            },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'FpDK5HNsphVcS7Z1H05Ht'
        }
      ]
    },
    {
      id: 'uVOldZa2AGNLoCKHqUK0N',
      layers: [
        {
          ddd: 0,
          ind: 69,
          ty: 4,
          nm: '',
          td: 1,
          ln: 'DPZaVLukIIGdln31wfwTW69',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'rc',
                  hd: false,
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [159.98, 43.2] }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        },
        {
          ddd: 0,
          ind: 63,
          ty: 0,
          nm: '',
          tt: 1,
          ln: 'precomp_cPuNPTE0pT5FWeMi9A1-463',
          sr: 1,
          ks: {
            a: { a: 0, k: [100000, 100000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [100000, 100000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'cZHQvT-GYNdE9GukcmrcW'
        }
      ]
    },
    {
      id: 'zd0PcO1C3u3ufSX4vXKzc',
      layers: [
        {
          ddd: 0,
          ind: 50,
          ty: 0,
          nm: '',
          ln: 'precomp_8n1J_Ebjly_nqsc78XC1450',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50033.32, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'xUzAeASnigKF-8SN4K9Xc'
        },
        {
          ddd: 0,
          ind: 62,
          ty: 0,
          nm: '',
          ln: 'precomp_cPuNPTE0pT5FWeMi9A1-462',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [49928.56, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'uVOldZa2AGNLoCKHqUK0N'
        }
      ]
    },
    {
      id: '3j538XlvO0H2Lfs9vdurv',
      layers: [
        {
          ddd: 0,
          ind: 20,
          ty: 0,
          nm: '',
          ln: 'precomp_TFfcH2L7z47QDjPha8YR620',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50021.6] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'uTM7gELkjuZAtkgas-flR'
        },
        {
          ddd: 0,
          ind: 49,
          ty: 0,
          nm: '',
          ln: 'precomp_4vSxucKJlAsJfc-3ZRb1q49',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50003.47, 49978.4] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'zd0PcO1C3u3ufSX4vXKzc'
        }
      ]
    },
    {
      id: 'zEd4xXdbpRwHYx9nOwE3f',
      layers: [
        {
          ddd: 0,
          ind: 71,
          ty: 4,
          nm: '',
          ln: 'nIYRu4UGVDHfhB7VCd69N71',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'rc',
                  hd: false,
                  p: { a: 0, k: [0, 0] },
                  r: {
                    a: 1,
                    k: [
                      { t: 0, s: [0], h: 1 },
                      { t: 0, s: [0], i: { x: 0, y: 1 }, o: { x: 0.5, y: 0 } },
                      { t: 12, s: [20], h: 1 },
                      {
                        t: 60,
                        s: [20],
                        i: { x: 0, y: 1 },
                        o: { x: 0.5, y: 0 }
                      },
                      { t: 92, s: [0], h: 1 }
                    ]
                  },
                  s: { a: 0, k: [608, 1080] }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0.95, 0.95, 0.95] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        }
      ]
    },
    { id: '5wAAtIDtwIsHqqvu-LrmY', layers: [] },
    { id: 'yiIBYsy-O6_QLy2ZfcdJ_', layers: [] },
    {
      id: 'MtBNCgdNO_7bVHdQqJuXq',
      layers: [
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: '',
          ln: 'precomp_ir7v_PLZ9Xya5y8j1dKd02',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 49807.5] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: '6sAH_ioRzYp9_gTeCk9nR'
        },
        {
          ddd: 0,
          ind: 19,
          ty: 0,
          nm: '',
          ln: 'precomp_mC67BZfFWlxoORwImab7h19',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 49714.5] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: '3j538XlvO0H2Lfs9vdurv'
        },
        {
          ddd: 0,
          ind: 70,
          ty: 0,
          nm: '',
          ln: 'precomp_F9UeIqTqyZfEDCbmLCKhq70',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'zEd4xXdbpRwHYx9nOwE3f'
        },
        {
          ddd: 0,
          ind: 72,
          ty: 0,
          nm: '',
          ln: 'precomp_YH0QKAZaqtz_hiC_tvVoS72',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: '5wAAtIDtwIsHqqvu-LrmY'
        },
        {
          ddd: 0,
          ind: 73,
          ty: 0,
          nm: '',
          ln: 'precomp_HjElhL1ctX935Hl4srx9w73',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'yiIBYsy-O6_QLy2ZfcdJ_'
        }
      ]
    },
    {
      id: '2-ZBPYIFDcC1XurhIN-Jf',
      layers: [
        {
          ddd: 0,
          ind: 75,
          ty: 4,
          nm: '',
          ln: 'p8A_G0s7rmM1rsHgKKPM575',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'rc',
                  hd: false,
                  p: { a: 0, k: [0, 0] },
                  r: {
                    a: 1,
                    k: [
                      { t: 0, s: [0], h: 1 },
                      { t: 0, s: [0], i: { x: 0, y: 1 }, o: { x: 0.5, y: 0 } },
                      { t: 30, s: [20], h: 1 }
                    ]
                  },
                  s: { a: 0, k: [608, 1080] }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: color },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        }
      ]
    },
    { id: 'tRerw1QwoaDwgTV0sJKa5', layers: [] },
    {
      id: '9LNL2MOZo_o8CC8mvGuKS',
      layers: [
        {
          ddd: 0,
          ind: 78,
          ty: 4,
          nm: '',
          ln: 'vZ5ui3adSLg7YHnvjk5Qo78',
          sr: 1,
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          shapes: [
            {
              ty: 'gr',
              hd: false,
              bm: 0,
              it: [
                {
                  ty: 'rc',
                  hd: false,
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [608, 1080] }
                },
                {
                  ty: 'fl',
                  hd: false,
                  bm: 0,
                  c: { a: 0, k: [0, 0, 0] },
                  r: 1,
                  o: { a: 0, k: 100 }
                },
                {
                  ty: 'tr',
                  nm: 'Transform',
                  a: { a: 0, k: [0, 0] },
                  o: { a: 0, k: 100 },
                  p: { a: 0, k: [0, 0] },
                  r: { a: 0, k: 0 },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 }
                }
              ],
              np: 0
            }
          ]
        }
      ]
    },
    {
      id: '5nMeWIwlaqP0Bk22mcRyp',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: '',
          ln: 'precomp_8haNv6M5-hbCRf8EhXiiP1',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: {
              a: 1,
              k: [
                { t: 0, s: [50608, 50000], h: 1 },
                {
                  t: 30,
                  s: [50608, 50000],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 60, s: [50000, 50000], h: 1 }
              ]
            },
            r: { a: 0, k: 0 },
            s: {
              a: 1,
              k: [
                { t: 0, s: [100, 100], h: 1 },
                { t: 0, s: [100, 100], i: { x: 0, y: 1 }, o: { x: 0.5, y: 0 } },
                { t: 12, s: [80, 80], h: 1 },
                { t: 62, s: [80, 80], i: { x: 0, y: 1 }, o: { x: 0.5, y: 0 } },
                { t: 92, s: [100, 100], h: 1 }
              ]
            },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'MtBNCgdNO_7bVHdQqJuXq'
        },
        {
          ddd: 0,
          ind: 74,
          ty: 0,
          nm: '',
          ln: 'precomp_atRii9uuNnb913sa3J_df74',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: {
              a: 1,
              k: [
                { t: 0, s: [50000, 50000], h: 1 },
                {
                  t: 0,
                  s: [50000, 50000],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 0, s: [50000, 50000], h: 1 },
                {
                  t: 30,
                  s: [50000, 50000],
                  i: { x: 0, y: 1 },
                  o: { x: 0.5, y: 0 }
                },
                { t: 60, s: [49392, 50000], h: 1 }
              ]
            },
            r: { a: 0, k: 0 },
            s: {
              a: 1,
              k: [
                { t: 0, s: [100, 100], h: 1 },
                { t: 0, s: [100, 100], i: { x: 0, y: 1 }, o: { x: 0.5, y: 0 } },
                { t: 30, s: [80, 80], h: 1 }
              ]
            },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: '2-ZBPYIFDcC1XurhIN-Jf'
        },
        {
          ddd: 0,
          ind: 76,
          ty: 0,
          nm: '',
          ln: 'precomp_RcnqjDnBrMESahNWezKNj76',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: 'tRerw1QwoaDwgTV0sJKa5'
        },
        {
          ddd: 0,
          ind: 77,
          ty: 0,
          nm: '',
          ln: 'precomp_LNwkOGyycF5w6tvaNKeeP77',
          sr: 1,
          ks: {
            a: { a: 0, k: [50000, 50000] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [50000, 50000] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 }
          },
          ao: 0,
          w: 100000,
          h: 100000,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
          refId: '9LNL2MOZo_o8CC8mvGuKS'
        }
      ]
    }
  ],
  ddd: 0,
  fr: 60,
  h: 1080,
  ip: 0,
  layers: [
    {
      ddd: 0,
      ind: 0,
      ty: 0,
      nm: '',
      ln: 'precomp_w4dpZGoZ1Kr0MXJmD_6_l0',
      sr: 1,
      ks: {
        a: { a: 0, k: [50000, 50000] },
        o: { a: 0, k: 100 },
        p: { a: 0, k: [304, 540] },
        r: { a: 0, k: 0 },
        s: { a: 0, k: [100, 100] },
        sk: { a: 0, k: 0 },
        sa: { a: 0, k: 0 }
      },
      ao: 0,
      w: 100000,
      h: 100000,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0,
      refId: '5nMeWIwlaqP0Bk22mcRyp'
    }
  ],
  meta: { g: 'https://jitter.video' },
  nm: 'Showreel:-Web-gallery-[remix]',
  op: 240,
  v: '5.7.4',
  w: 608
})
