import { Flex, Text, Image, Box } from '@chakra-ui/react'
import Fireworks from '../Fireworks'
import bg from '../../lotties/end.json'
import LottieAnimation from '../Animations/LottieAnimation'

export default function Card12() {
  return (
    <Flex
      height="100%"
      w="100%"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box zIndex={1} w="100%" height="100%" position="absolute">
        <LottieAnimation height={'100%'} animation={bg} delay={250} />
      </Box>
      <Fireworks />
    </Flex>
  )
}
