import { Box, Flex, Text } from '@chakra-ui/react'
import { TemplateType } from '../../interfaces/templates'
import { HighlightAnimation } from '../stateless/HighlightAnimation'
import { ClientTypeEnum, ICard2 } from '../../graphql/year-review.query'
import UnderlineAnimation from '../stateless/UnderlineAnimation'

interface Props extends ICard2 {
  type: ClientTypeEnum
}

export default function Card2({ title, type }: Props) {
  const textByTemplates: { [key in ClientTypeEnum]: string } = {
    withoutGA: 'começamos',
    GA: 'começamos',
    free: 'experimentamos'
  }
  const hasTwoWords = (title || '').split(' ').length > 1

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color="white"
    >
      <Text fontSize={['30px']}>
        Juntos, continuamos a{' '}
        <UnderlineAnimation>
          transformação da experiência de compra
        </UnderlineAnimation>{' '}
        online através de vídeos, trazendo{' '}
        <Text as="span" fontWeight="900">
          humanização e interatividade
        </Text>{' '}
        para o site da
        <br /> <HighlightAnimation delay={1.5}>{title}.</HighlightAnimation>
      </Text>
    </Flex>
  )
}
