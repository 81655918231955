import { useDisclosure } from '@chakra-ui/react'
import { useEffect, useLayoutEffect } from 'react'

type Props = {
  children: any
  delay: number
}
export default ({ children, delay }: Props) => {
  const { isOpen, onOpen } = useDisclosure()

  useLayoutEffect(() => {
    let timeout = setTimeout(() => {
      onOpen()
    }, delay * 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])
  if (!isOpen) return null

  return children
}
