import React from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { theme } from './styles/theme'
import { Global } from '@emotion/react'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  cache: new InMemoryCache()
})
ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Global
        styles={`
.max-height {
  min-height: 100vh;
  height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  max-height: -webkit-fill-available;
}
.loading-bar {
  -webkit-animation: loading-bar-animation 2500ms infinite ;
          animation: loading-bar-animation 2500ms infinite ;
}
.fade {
  -webkit-animation: fade-animation 500ms both ;
          animation: fade-animation 500ms both ;
}
*{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

@keyframes fade-animation {
  0% {
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes loading-bar-animation {
  0% {
    width: 25%;
  }
  25%{
    width: 100%;
    margin-left: 0%;
  }
  50%{
    width: 25%;
    margin-left: 75%;
  }
  75% {
    width: 100%;
    margin-left: 0%;
  }
  100% {
    width: 25%;
    margin-left: 0%;
  }


}`}
      />
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
