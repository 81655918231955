import Lottie from 'react-lottie'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Box } from '@chakra-ui/react'

type Props = {
  animation: any
  delay: number
  width?: number | string
  height?: number | string
  onClick?: () => void
}
export default ({ animation, delay, height, width, onClick }: Props) => {
  const [paused, setPaused] = useState(true)

  const animationRef = useRef<any>(null)
  useLayoutEffect(() => {
    let interval = setTimeout(() => {
      setPaused(false)
    }, delay)

    return () => {
      clearTimeout(interval)
    }
  }, [])

  const handleMouseEnter = () => {
    if (animationRef.current) {
      console.log(animationRef.current)
      animationRef.current.play()
    }
  }

  const handleMouseLeave = () => {
    if (animationRef.current) {
      animationRef.current.pause()
    }
  }
  return (
    <Box
      onClick={onClick}
      _hover={{ opacity: 0.9, scale: 1.1 }}
      transition="250ms linear all"
    >
      <Lottie
        ref={animationRef}
        isPaused={paused}
        isStopped={paused}
        width={width}
        height={height}
        options={
          {
            loop: false,
            autoplay: false,
            animationData: animation,
            rendererSettings: {
              // preserveAspectRatio: 'xMidYMid meet',
              // viewBoxSize: '700 0 2400 590'
            }
          } as any
        }
      />
    </Box>
  )
}
