import { Box, Flex, Image, SlideFade, Text } from '@chakra-ui/react'
import { ItemColored } from '../stateless/ItemColored'
import { HighlightAnimation } from '../stateless/HighlightAnimation'
import { numberWithCommas } from '../../utils/helpers/numberFormats'
import {
  ClientTypeEnum,
  ICard6,
  ICard7,
  ICard8,
  ICard9
} from '../../graphql/year-review.query'
import UnderlineAnimation from '../stateless/UnderlineAnimation'
import bg from '../../lotties/card-9.json'
import LottieAnimation from '../Animations/LottieAnimation'
import { TypeAnimation } from 'react-type-animation'
import Delay from '../stateless/Delay'
interface Props extends ICard9 {}

export default function Card9({ image }: Props) {
  return (
    <Flex
      height="100%"
      w="100%"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box zIndex={1} w="100%" height="100%" position="absolute">
        <LottieAnimation height={'100%'} animation={bg} delay={250} />
      </Box>
      <Box zIndex={2}>
        <SlideFade offsetY="20px" delay={1.5} in={true}>
          <Box
            mt="22px"
            bg="white"
            borderRadius="5px"
            overflow="hidden"
            zIndex={2}
            display={'flex'}
            style={{
              aspectRatio: '1/1'
            }}
            flex="1"
            maxHeight={100}
            maxWidth={100}
          >
            <Image w="100%" objectFit="cover" src={image} />
          </Box>
        </SlideFade>
      </Box>
    </Flex>
  )
}
