import { gql, useQuery } from '@apollo/client'

export interface ICard1 {
  title: string
}
export interface ICard2 {
  title: string
}
export interface IVideo {
  url: string
  duration: number
  thumbnail: string
}
export interface ICard3 {
  videosTotal: string
  viewsTotal: string
  userViewsTotals: string
}

export interface ICard4 {
  highlightVideo: IVideo
}
export interface ICard5 {
  retentionTax: string
  retentionTimeAverage: string
}

export interface ICard6 {
  approvalRate: number
  conversionIncrease: string
  widdeConversion: string
  conversion: string
}

export interface ICard7 {
  approvalRate: number
  comments: string[]
}
// 8 nao tem nada
export interface ICard8 {}

export interface ICard9 {
  image: string
}

export interface CardData
  extends ICard1,
    ICard2,
    ICard3,
    ICard4,
    ICard5,
    ICard6,
    ICard7,
    ICard8,
    ICard9 {}
export enum ClientTypeEnum {
  'GA' = 'GA',
  'withoutGA' = 'withoutGA',
  'free' = 'free'
}

interface YEAR_REVIEW_RESPONSE {
  review: {
    clientType: ClientTypeEnum
    data: CardData
  }
}

const YEAR_REVIEW = gql`
  query review($invite: String!) {
    review(invite: $invite) {
      clientType
      data {
        highlightVideo {
          url
          thumbnail
          duration
        }
        comments
        image
        conversion
        widdeConversion
        conversionIncrease
        approvalRate
        retentionTimeAverage
        retentionTax
        userViewsTotals
        viewsTotal
        videosTotal
        title
      }
    }
  }
`
interface YEAR_REVIEW_PARAMS {
  invite?: string
}

export const useYearReview = ({ invite }: YEAR_REVIEW_PARAMS) => {
  const { data, loading } = useQuery<YEAR_REVIEW_RESPONSE, YEAR_REVIEW_PARAMS>(
    YEAR_REVIEW,
    { variables: { invite }, skip: !invite }
  )

  return { data: data?.review, loading }
}
