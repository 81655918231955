import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { CardData, useYearReview } from '../graphql/year-review.query'
import { useYearReviewCardView } from '../graphql/year-review-card-view.mutation'
import useStoryFlows from './useStoryFlow'

const USER_ID = 'USER_ID'

export function useInvite(invite: string) {
  const { data, loading } = useYearReview({ invite })
  const [userCookie, setUserCookie] = useState<string>('')
  const { addYearReviewMutation } = useYearReviewCardView()
  const stories = useStoryFlows(data || ({} as any))
  const cardData = data?.data || ({} as CardData)
  useEffect(() => {
    const userId = localStorage.getItem(USER_ID)
    if (userId) {
      setUserCookie(userId)
    } else {
      const userId = uuid()
      localStorage.setItem(USER_ID, userId)
      setUserCookie(userId)
    }
  }, [])
  const setStoryView = useCallback(
    cardIndex => {
      if (!data) return
      console.log('aaa')
      addYearReviewMutation({
        variables: { cardIndex, invite, userCookie }
      })
    },
    [data]
  )

  return {
    data: cardData,
    stories,
    loading,
    setStoryView,
    title: data?.data.title || '',
    logo: data?.data.image || ''
  }
}
