import { Box, Img, SlideFade as SlideFade, Text } from '@chakra-ui/react'
import StoryLayout from '../../layouts/StoryLayout'
import button from '../../lotties/button.json'
import logo from '../../lotties/logo.json'
import LottieAnimation from '../Animations/LottieAnimation'

type Props = {
  name: string
  logo: string
  onClick: () => void
}

export default ({ logo: _logo, name, onClick }: Props) => {
  return (
    <StoryLayout hiddeWiddeLogo>
      <Box
        w="100%"
        h="100%"
        pt="48px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
      >
        <Box
          style={{
            aspectRatio: '161/65'
          }}
          flex="1"
          maxHeight={65}
          maxWidth={161}
          zIndex={2}
        >
          <LottieAnimation height={65} animation={logo} delay={250} />
        </Box>
        <Box
          flex="1"
          w="100%"
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          py="16px"
          overflow="auto"
        >
          <Text color="#E5F754" fontSize="24px" fontWeight="extrabold">
            {name}
          </Text>
          <Box
            style={{
              aspectRatio: '1/1'
            }}
            flex="1"
            maxHeight={65}
            maxWidth={65}
            mt="22px"
            bg="white"
            borderRadius="100%"
            zIndex={2}
          >
            <Img w="100%" objectFit="cover" src={_logo} />
          </Box>

          <SlideFade offsetY="20px" delay={0.2} in={true}>
            <Text
              my={['16px', '32px']}
              color="white"
              textAlign="center"
              fontSize="1.2rem"
            >
              O ano de 2023 foi muito importante para a Widde, e a{' '}
              <Text as="span" fontWeight="700">
                {name}
              </Text>{' '}
              fez parte disso. <br />
            </Text>
          </SlideFade>
          <SlideFade offsetY="20px" delay={0.5} in={true}>
            <Text as="span" fontWeight="700" textAlign="center">
              Vem ver o que preparamos para você!
            </Text>
          </SlideFade>
          <br />
          <Box
            style={{
              aspectRatio: '1/1'
            }}
            flex="1"
            maxHeight={192}
            maxWidth={192}
          >
            <LottieAnimation
              height={'100%'}
              onClick={onClick}
              width={'100%'}
              animation={button}
              delay={1500}
            />
          </Box>
          {/* 
          <LottieAnimation
            height={192}
            onClick={onClick}
            width={192}
            animation={button}
            delay={1500}
          /> */}
        </Box>
      </Box>
    </StoryLayout>
  )
}
