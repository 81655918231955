import { gql, useMutation } from '@apollo/client'

const YEAR_REVIEW_CARD_VIEW = gql`
  mutation addReviewCardView(
    $invite: String!
    $cardIndex: Float!
    $userCookie: String!
  ) {
    addReviewCardView(
      input: { invite: $invite, cardIndex: $cardIndex, userCookie: $userCookie }
    )
  }
`
interface YEAR_REVIEW_CARD_VIEW_PARAMS {
  invite: string
  cardIndex: number
  userCookie: string
}
export const useYearReviewCardView = () => {
  const [addYearReviewMutation] = useMutation<
    any,
    YEAR_REVIEW_CARD_VIEW_PARAMS
  >(YEAR_REVIEW_CARD_VIEW)
  return { addYearReviewMutation }
}
