import { Stack, Box, Text } from '@chakra-ui/react'
import { ItemColored } from '../stateless/ItemColored'
import { motion } from 'framer-motion'
import { NumberCounter } from '../Animations/NumbersCount'
import { ICard5 } from '../../graphql/year-review.query'
interface Props extends ICard5 {}

export default function Card5({
  retentionTax: retention,
  retentionTimeAverage: avgMinutes
}: Props) {
  const numberOfTexts = [
    {
      bg: 'green.100',
      color: 'primary.100'
    },
    {
      bg: 'pink.100',
      color: 'primary.100'
    },
    {
      bg: 'orange.100',
      color: 'primary.100'
    },
    {
      bg: 'purple.100',
      color: 'secondary.100'
    },
    {
      bg: 'primary.100',
      color: 'white'
    }
  ]

  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color="primary.100"
      w="100%"
      h="100%"
      spacing="32px"
    >
      <Text textAlign="center" fontSize={['18px', '24px']} mb={['8px']}>
        E veja como os vídeos melhoram a{' '}
        <Text as="span" fontWeight="700">
          taxa de retenção:
        </Text>
      </Text>

      <Box w="100%" mb="24px">
        {numberOfTexts.map((item, index) => (
          <motion.div
            initial={{ opacity: 0, y: 45, scale: 0.9 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            transition={{
              duration: Math.max(0.25, 1.5 - 0.25 * index),
              delay: index * 0.25
              // default: { ease: "easeOut" },
            }}
          >
            <ItemColored {...item}>
              +
              <NumberCounter
                to={+(retention || '').toString().replace('%', '')}
                delay={index * 0.25}
                duration={Math.max(0.25, 1.5 - 0.25 * index)}
              />
              %
            </ItemColored>
          </motion.div>
        ))}
      </Box>

      <Text
        textAlign="center"
        fontSize={['18px', '24px']}
        fontWeight="700"
        maxW="90%"
      >
        Isso representa a média
        <br />
        de {avgMinutes} minutos de retenção no seu ecommerce.
      </Text>
    </Stack>
  )
}
