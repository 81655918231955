import { Box, Flex, SlideFade, Text } from '@chakra-ui/react'
import { ItemColored } from '../stateless/ItemColored'
import { HighlightAnimation } from '../stateless/HighlightAnimation'
import { numberWithCommas } from '../../utils/helpers/numberFormats'
import {
  ClientTypeEnum,
  ICard6,
  ICard7,
  ICard8
} from '../../graphql/year-review.query'
import UnderlineAnimation from '../stateless/UnderlineAnimation'
import bg from '../../lotties/new-era.json'
import LottieAnimation from '../Animations/LottieAnimation'
import { TypeAnimation } from 'react-type-animation'
import { useLayoutEffect, useState } from 'react'
import Delay from '../stateless/Delay'
interface Props extends ICard8 {}

export default function Card8({}: Props) {
  return (
    <Flex
      height="100%"
      w="100%"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box zIndex={1} w="100%" height="100%" position="absolute">
        <LottieAnimation height={'100%'} animation={bg} delay={250} />
      </Box>
    </Flex>
  )
}
