import { Box, Text, As } from '@chakra-ui/react'
import { motion } from 'framer-motion'

interface Props {
  children: any
  // as?: As;
  bg?: string
  color?: string
  delay?: number
  animationDuration?: number
}

export function HighlightAnimation({
  children,
  bg = '#E5F754',
  color = '#050508',
  delay = 0.5
}: // animationDuration = 1,
Props) {
  return (
    <Text as="span" position="relative" height="auto" display="inline-block">
      <motion.div
        initial={{
          content: '',
          position: 'absolute',
          zIndex: 1,
          backgroundColor: bg,
          height: '100%',
          width: '100%',
          transform: 'scaleX(0)',
          transformOrigin: 'center right'
        }}
        animate={{
          transform: 'scaleX(1)',
          transformOrigin: 'center left'
        }}
        transition={{
          duration: 0.4,
          delay,
          ease: 'easeInOut'
        }}
        style={{
          top: 0,
          left: 0,
          position: 'absolute',
          background: bg
        }}
      />

      <Text
        as="span"
        style={{
          whiteSpace: 'nowrap'
        }}
        position="relative"
        zIndex={2}
        color={color}
      >
        {children}
      </Text>
    </Text>
  )
}
