import style from './UnderlineAnimation.module.css'

type Props = {
  children: any
  color?: string
}

export default ({ children, color = '#FFF' }: Props) => (
  <span
    className={style.underline}
    style={
      {
        '--color': color
      } as any
    }
  >
    {children}
  </span>
)
