import { Flex, Text, Box, Image } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useState, RefObject, useEffect, useRef } from 'react'
import LottieAnimation from '../Animations/LottieAnimation'

import bg from '../../lotties/card-10.json'

export default function Card10() {
  return (
    <Flex
      height="100%"
      w="100%"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box zIndex={1} w="100%" height="100%" position="absolute">
        <LottieAnimation height={'100%'} animation={bg} delay={250} />
      </Box>
    </Flex>
  )
}
