import { useEffect, useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Stories from 'react-insta-stories'
import Start from './components/Start'
import { DEFAUNT_INTERVAL } from './utils/const/default-interval'
import { useInvite } from './hook/useInvite'
import Loading from './components/Loading'
import styles from './App.module.css'
// force deploy

function App({ inviteName }: { inviteName: string }) {
  const [started, setStarted] = useState(false)
  const [prevent, setPrevent] = useState<boolean>(false)
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0)

  const {
    stories: _stories,
    data,
    setStoryView,
    loading,
    title,
    logo
  } = useInvite(inviteName)
  const stories = _stories

  console.log(title, _stories)
  useEffect(() => {
    const currentStory = _stories?.[currentStoryIndex]
    if (currentStory) {
      setStoryView(currentStory.id)
    }
  }, [currentStoryIndex])

  useEffect(() => {
    if (started) {
      setStoryView(1)
    }
  }, [started])

  if (loading) return <Loading />
  if (!data)
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        fontSize="32px"
        textAlign="center"
        color="white"
        h="100vh"
      >
        Link inválido
      </Flex>
    )
  console.log('S', stories)
  return (
    <Flex
      width="100%"
      // alignItems="center"
      // justifyContent="center"
      className=" max-height"
      overflow="hidden"
      transition="250ms linear all"
      alignItems="center"
      justifyContent="center"
      // background="red"
      // bgImage="url(/assets/backgrounds/stars-bg.png)"
      background={['#050508', '#191919']}
    >
      <Box className={styles['story-card']}>
        {!started ? (
          title && (
            <Start logo={logo} name={title} onClick={() => setStarted(true)} />
          )
        ) : (
          <Stories
            stories={stories}
            defaultInterval={DEFAUNT_INTERVAL}
            width={'100%'}
            height={'100%'}
            preventDefault={prevent}
            onStoryEnd={(index: number) => setStoryView(_stories?.[index].id)}
            onAllStoriesEnd={(e: any) => setPrevent(!!e)}
            keyboardNavigation
            onStoryStart={(e: any) => {
              setCurrentStoryIndex(e)
            }}
          />
        )}
      </Box>
    </Flex>
  )
}

export default () => {
  const urlSplited = window.location.href.split('?')[0].split('/')
  const inviteName = urlSplited[urlSplited.length - 1] || ''
  // if (inviteName == "admin")
  //   return (
  //     <AuthContext>
  //       <Admin />
  //     </AuthContext>
  //   );
  // if (!inviteName) return null
  return <App inviteName={inviteName} />
}
